import React from 'react';
import { Select } from '@screentone/core';

import useEditImage from '../../../../hooks/useEditImage';

const Gravity = ({ panelKey }: { panelKey: string }) => {
  const { setData, getData } = useEditImage();
  const gravity = getData('gravity', panelKey);

  return (
    <Select
      name="name"
      value={gravity}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData('gravity', e.target.value, panelKey)}
    >
      <option value="">Auto/Focal Area</option>
      <option value="center">Center</option>
    </Select>
  );
};

export default Gravity;
