import React, { useEffect, useRef, useState } from 'react';
import { ErrorPage, useAuth } from '@screentone/addon-auth-wrapper';
import { Group, PageWrapper, Box, Grid } from '@screentone/core';

import { Helmet as Head } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import useAlert from '../../hooks/useAppAlerts';
import useConfig from '../../hooks/useConfig';
import useImageDetail from '../../hooks/useImageDetail';
import useSearch from '../../hooks/useSearch';

import Content from '../../components/EditImage/Content';
import ImageHeader from '../../components/EditImage/Content/ImageHeader';
import PanelHeader from '../../components/ImageDetails/PanelHeader';
import Panels from '../../components/ImageDetails/Panels';
import PageNavigation from '../../components/ImageDetails/PageNavigation';
import ImageTabBar from '../../components/ImageDetails/ImageTabBar';
import ActivityLogs from '../../components/ImageDetails/ActivityLogs';

import { iframe, helpers } from '../../utils';

import type { ImageType, PropertyType, EnvironmentType } from '../../types';
import { getAllPublishedIds } from '../../utils/helpers';

function ImageDetailsLayout() {
  const { user, userAccess } = useAuth();
  const {
    authFetch,
    session: { appUrl, env, property, IMAGE_DOMAINS },
  } = useConfig();
  const { im: publishedIdFromUrl, type: pageType = 'image' } = useParams();
  const isBatchFlow = pageType === 'batch';
  const {
    image,
    imageId,
    publishedId,
    publishedIdsObj,
    setImage: setDetailsImage,
    transformationType,
  } = useImageDetail();
  const [isError, setIsError] = useState(false);
  const { setImage: setSearchImage } = useSearch();

  const { alertComponent, setAlert } = useAlert();
  const navigate = useNavigate();

  const navElem = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const allPublishedIds = getAllPublishedIds(image, property);
    // publishedId is undefined when coming from search page and original if metadata is updated on the original image
    if (!publishedId && allPublishedIds.length === 1 && !publishedIdFromUrl) {
      navigate(`/${property}/${pageType}/${image.asset_id}/details/${allPublishedIds[0]}`, { replace: true });
    }
  }, [image]);

  useEffect(() => {
    const element = navElem.current;
    setHeight(element?.clientHeight as number);
  }, [navElem.current]);

  const setImage = (image: ImageType) => {
    setDetailsImage(image);
    setSearchImage(image);
  };

  const options = {
    appUrl,
    property: property as PropertyType,
    env: env as EnvironmentType,
    image: image as ImageType,
  };

  useEffect(() => {
    // Fetch the image data whenever the URL changes
    if ((!image || (image.isDynamic && !image.additional_resources)) && publishedIdFromUrl) {
      authFetch(`/api/:property/${publishedIdFromUrl}`)
        .then((data) => {
          const updatedImage = { ...image, ...data };
          if (updatedImage && updatedImage.additional_resources?.length === 0 && image.additional_resources) {
            updatedImage.additional_resources = image.additional_resources;
          }
          setImage(updatedImage);
        })
        .catch((err: Error) => {
          console.error('Getting Image from published ID: ', err);
          // setAlert("Error updating image's metadata, try again.", { type: 'error' });
          // setIsError(true);
        });
    }
  }, [publishedIdFromUrl, property]);

  const uploadedByCurrentUser = user?.dj_user_id.toLowerCase() === image?.metadata?.dj_user_id;
  const imageSupportsEdit = !!publishedId && !!['cldDefault'].includes(transformationType);
  const canEditFocalPoint =
    imageSupportsEdit &&
    !(image?.isChart || image.isDynamic) &&
    (uploadedByCurrentUser || userAccess(`${property}.edit`) || userAccess(`${property}.edit.focalPoint`));

  // if pageType is not batch or image then show error page
  if (pageType !== 'batch' && pageType !== 'image') {
    return <ErrorPage type="invalid_request" />;
  }

  return (
    <>
      <Head>
        <title>Images - Details for {publishedId || imageId}</title>
        <meta
          name="thumbnail"
          content={helpers.getImageUrl({
            image,
            publishedId,
            property,
            defaultPreviewUrl: true,
            IMAGE_DOMAINS,
          })}
        />
      </Head>
      {alertComponent}

      <PageNavigation
        image={image}
        showAddToSource={iframe.isFrame()}
        setImage={setImage}
        divContainerRef={navElem}
        publishedIdsObj={publishedIdsObj}
      />

      <PageWrapper
        key={publishedId}
        style={{
          position: 'relative',
          top: `${height}px`,
          maxWidth: 'calc(var(--st-grid-page-fullwidth) + 10rem)',
          paddingTop: 'var(--st-header-height)',
        }}
        padding={{ bottom: 'xl' }}
      >
        <Group gap="none" fullWidth margin={{ vertical: 'md' }} valign="end">
          <ImageTabBar />
        </Group>

        {isError && publishedIdFromUrl && !Object.keys(publishedIdsObj).includes(publishedIdFromUrl) ? (
          <ErrorPage type="invalid_request" />
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Col xs={12} md={8}>
                <ImageHeader canEditFocalPoint={canEditFocalPoint} />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <PanelHeader />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col xs={12} md={8}>
                <Content />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <Panels />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        )}
        {!isBatchFlow ? (
          <Box margin={{ top: 'md', bottom: 'md' }} padding={{ all: 'md', top: 'sm' }}>
            <ActivityLogs env={options.env} property={property} />
          </Box>
        ) : null}
      </PageWrapper>
    </>
  );
}

export default ImageDetailsLayout;
