import React, { useState, createContext, useCallback, useContext, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileRejection, useDropzone } from 'react-dropzone';

import { useAuth } from '@screentone/addon-auth-wrapper';

import actions from './actions';
import { defaultUploadState } from './allReducer';
import { handleImageFiles, retryUploads, upload, retryUploadsPromo } from './effects';
import mergedUploadReducer from './mergedUploadReducer';
import { constants } from '../../utils';
import { useConfig } from '../useConfig';

import UseUploadType, { Files } from './types';

import { getAvailablePropertiesKeys, localStorageHelper } from '../../utils/helpers';
import UploadBox from '../../components/Upload/UploadBox';

import '../../App.module.css';

import styles from '../../layouts/Upload/Upload.module.css';

const UploadOverlay = () => {
  return (
    <div className={`${styles.uploadWrapperWithoutHeader} ${styles.onDragOver}`}>
      <div className={styles.uploadWrapperContent}>
        <UploadBox />
      </div>
    </div>
  );
};

const UploadContext =
  createContext<
    | {
        actions: typeof actions;
        dispatch: UseUploadType.Merged.Dispatch;
        effects: {
          handleImageFiles(files: File[], errors: FileRejection[]): void;
          retryUploads(): void;
          upload(type: 'single' | 'dynamic'): void;
          retryUploadsPromo(): void;
        };
        state: UseUploadType.Merged.State;
        type: 'single' | 'dynamic';
        onDragOver: boolean;
        getRootProps: ReturnType<typeof useDropzone>['getRootProps'];
      }
    | undefined
  >(undefined);

/** Arguments for UploadProvider */
type UploadProviderArguments = {
  type?: 'single' | 'dynamic';
  children?: React.ReactNode;
};

/** Provider for context */
export const UploadProvider = ({ type = 'single', children }: UploadProviderArguments) => {
  const { user } = useAuth();
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(mergedUploadReducer, defaultUploadState);
  const [onDragOver, setOnDragOver] = useState(false);

  const pathnameArray = location.pathname.split('/');
  const uploadPage = pathnameArray[3] === 'upload';
  const propertyKeys = getAvailablePropertiesKeys();

  const isCurrentProperty = propertyKeys.includes(property) && pathnameArray[1] === property;

  const maxImages = constants.MAX_FILES_IN_UPLOADER[type];

  useEffect(() => {
    if (property) {
      dispatch({ type: actions.ALL.RESET_UPLOAD });
      localStorageHelper.deleteItem(property);
    }
  }, [property]);

  const effects = {
    handleImageFiles: useCallback(
      (acceptedFiles: File[], rejectedFiles: FileRejection[]) =>
        handleImageFiles({
          acceptedFiles: acceptedFiles as Files.Accepted,
          currentNumberOfAcceptedFiles: state.files.accepted.length,
          dispatch,
          rejectedFiles: rejectedFiles as unknown as Files.Rejected,
          user: user || {},
          property,
          maxImages,
          uploadType: type,
          authFetch,
        }),
      [property, maxImages, state.files.accepted.length, type, user],
    ),
    retryUploads: useCallback(
      () => retryUploads({ authFetch, dispatch, property, state }),
      [authFetch, property, dispatch, state],
    ),
    upload: useCallback(
      (uploadType: 'single' | 'dynamic') => upload({ authFetch, dispatch, property, state, type: uploadType }),
      [authFetch, property, dispatch, state],
    ),
    retryUploadsPromo: useCallback(
      () => retryUploadsPromo({ authFetch, dispatch, property, state }),
      [authFetch, property, dispatch, state],
    ),
  };

  const { status } = state.upload;
  const isComplete = status.length > 0 ? status.every((stat) => stat === 'complete') : false;
  const isDisabled = !isCurrentProperty || (status.length !== 0 && !isComplete);

  const { getRootProps, getInputProps } = useDropzone({
    accept: constants.validFiles[type],
    maxSize: constants.MAX_FILE_SIZE,
    noDragEventsBubbling: true,
    disabled: isDisabled,
    onDrop: (files: File[], errors: FileRejection[]) => {
      property && localStorageHelper.deleteItem(property);

      if (isComplete) {
        dispatch({ type: actions.ALL.RESET_UPLOAD });
      }

      effects.handleImageFiles(files, errors);

      if (!uploadPage) {
        navigate(`/${property}/images/upload`);
      }
      setOnDragOver(false);
    },
    onDragEnter: (e) => {
      setOnDragOver(true);
    },
    onDragOver: (e) => {
      setOnDragOver(true);
    },
    onDragLeave: (e) => {
      setOnDragOver(false);
    },
  });

  const shouldRenderUploadLayout = onDragOver && !uploadPage;

  const { onClick, ...dropZoneProps } = getRootProps();

  return (
    <UploadContext.Provider
      value={{
        actions,
        dispatch,
        effects,
        state,
        type,
        onDragOver,
        getRootProps,
      }}
    >
      <div {...dropZoneProps}>
        <input data-testid="file-input-inside" {...getInputProps()} />
        {shouldRenderUploadLayout && <UploadOverlay />}
        {children}
      </div>
    </UploadContext.Provider>
  );
};

/**
 * Hook for getting and setting information about images in the uploader
 * For more information about the pattern used, see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const useUpload = () => {
  const context = useContext(UploadContext);

  if (context === undefined) {
    throw new Error('useUpload must be used within a UploadProvider');
  }

  return context;
};

export default useUpload;
