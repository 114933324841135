import React from 'react';
import { Helmet as Head } from 'react-helmet';

import UploadLayout from '../../../../layouts/Upload';
import { UploadProvider } from '../../../../hooks/useUpload';

const UploadPage = () => {
  return (
    <>
      <Head>
        <title>Images - Upload</title>
      </Head>
      <UploadProvider type="dynamic">
        <UploadLayout />
      </UploadProvider>
    </>
  );
};

export default UploadPage;
