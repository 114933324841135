import { User } from '@screentone/addon-auth-wrapper';
import { Group } from '@screentone/core';
import React from 'react';

import ImageCard from '../ImageCard';
import ButtonAddToLightbox from '../Buttons/AddToLightbox';
import ButtonRemoveFromLightbox from '../Buttons/RemoveFromLightbox';
import { ImageType } from '../../types';
import { iframe } from '../../utils';
import { checkIfPublished, createPublishedIdsObj } from '../../utils/helpers';
import { useConfig } from '../../hooks/useConfig';
import AddToSourceButton from '../Buttons/AddToSource';

type ImageListProps = {
  /** array of images */
  images: ImageType[];
  /** current logged in users email */
  user: User;
  /** array of selected images */
  selected?: ImageType[];
  /** callback for adding to lightbox */
  onAddToLightbox?: (img: ImageType, id: string) => void;
  /** callback for removing from lightbox */
  onRemoveFromLightbox?: (img: ImageType) => void;
  /** callback when publish button is pressed, also controls whether it appears */
  setImage(img: ImageType): void;
  /** published id */
  publishedId?: string;
};

function ImageList({
  images,
  selected = [],
  onAddToLightbox,
  onRemoveFromLightbox,
  setImage,
  publishedId,
}: ImageListProps) {
  const {
    session: { property, IMAGE_DOMAINS, PREVIEW_SIZES },
  } = useConfig();

  if (!images || images.length === 0) return null;
  return (
    <Group direction="column" gap="sm" margin={{ bottom: 'xl' }}>
      {images.map((img) => {
        const isPublished = checkIfPublished(img, property);
        const isSelected = !!selected?.find((i) => i.asset_id === img.asset_id);
        const additionalProps: { additionalActions?: React.ReactNode } = {};
        const publishedIdsObj = createPublishedIdsObj(img as ImageType, IMAGE_DOMAINS, PREVIEW_SIZES, property);

        if (onAddToLightbox && onRemoveFromLightbox) {
          additionalProps.additionalActions = isSelected ? (
            <ButtonRemoveFromLightbox onClick={() => onRemoveFromLightbox(img)} />
          ) : (
            <ButtonAddToLightbox
              publishedIdsObj={publishedIdsObj}
              onClick={(e: any, info: any) => {
                onAddToLightbox(img, info?.id);
              }}
            />
          );
        } else if (iframe.isFrame() && isPublished) {
          additionalProps.additionalActions = <AddToSourceButton image={img} />;
        }

        return (
          <Group.Item key={img.asset_id} fullWidth>
            <ImageCard
              image={img}
              selected={isSelected}
              {...additionalProps}
              setImage={setImage}
              publishedId={publishedId}
            />
          </Group.Item>
        );
      })}
    </Group>
  );
}

export default ImageList;
