import { User } from '@screentone/addon-auth-wrapper';
import type { ImageType, PropertyType } from '../types';
import useConfig from '../hooks/useConfig';
import { TAGS } from './constants';

type ShareTextParams = {
  appUrl: string;
  user: User;
  property: PropertyType;
  image: ImageType;
  publishedId?: string;
};

function getCopyMetadata({ appUrl, user, property, image, publishedId }: ShareTextParams) {
  
  const {
    session: { IMAGE_DOMAINS },
  } = useConfig();
  
  const IMAGE_DOMAIN = IMAGE_DOMAINS?.default;

  const imageID = publishedId || image.asset_id;
  const imageUrl = publishedId ? `${IMAGE_DOMAIN}${publishedId || ''}` : image.secure_url;
  const imageDetailsUrl = `${appUrl}/${property}/image/${publishedId || image.asset_id}`;
  const slug = publishedId && image.context?.[`published_label_${publishedId}`];

  const plainText = [];
  const html = [];
  // Add user who shared the image
  plainText.push(
    `${user.name || user?.display_name || user?.given_name + ' ' + user?.family_name} has shared ${publishedId ? `publishedId ${imageID}` : 'an UNPUBLISHED image'}${slug ? ` (${slug})` : ''} with you.`
  );
  html.push(
    `<p><strong>${user.name || user?.display_name || (user?.given_name + ' ' + user?.family_name)}</strong> has shared ${publishedId ? `image <a href="${imageUrl}">${imageID}</a>` : `an <a href="${imageUrl}">UNPUBLISHED</a> image`}${slug ? ` (${slug})` : ''} with you.</p>`
  );
  // Add image ID and preview if supported

  html.push(`<img src="${imageUrl}${publishedId ? '/preview' : ''}" />`);

  if (publishedId) {
    plainText.push(`Image ID: ${imageID}`);
    html.push(`<p><strong>Image ID:</strong> ${imageID}</p>`);
  }

  if (slug) {
    plainText.push(`Slug: ${slug}`);
    html.push(`<p><strong>Slug:</strong> ${slug}</p>`);
  }

  // Add headline if it exists
  if (image.metadata?.headline) {
    plainText.push(`Headline: ${image.metadata.headline}`);
    html.push(`<p><strong>Headline:</strong> ${image.metadata?.headline}</p>`);
  }

  // Add caption if it exists
  if (image.metadata?.caption) {
    plainText.push(`Caption: ${image.metadata.caption}`);
    html.push(`<p><strong>Caption:</strong> ${image.metadata?.caption}</p>`);
  }

  // Add credit
  const credit = image.metadata?.credit || TAGS.UNCREDITED;
  plainText.push(`Credit: ${credit}`);
  html.push(`<p><strong>Credit:</strong> ${credit}</p>`);

  // Add image public URL
  if (publishedId) {
    plainText.push(`Image URL: ${imageUrl}`);
    html.push(`<p><strong>Image URL:</strong> <a href="${imageUrl}">${imageUrl}</a></p>`);
  }

  // Add image details tool URL
  plainText.push(`Image details URL: ${imageDetailsUrl}`);
  html.push(`<p><strong>Image details URL:</strong> <a href="${imageDetailsUrl}">${imageDetailsUrl}</a></p>`);

  return [plainText.join('\n'), html.join('\n')];
}

function getCopyMetadataText({ appUrl, user, property, image, publishedId }: ShareTextParams) {
  return getCopyMetadata({ appUrl, user, property, image, publishedId });
}

export default getCopyMetadataText;
export { getCopyMetadataText, getCopyMetadata };
