import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dropdown,
  Group,
  Typography,
  IconCross,
  IconCrop,
  IconFocalPoint,
  IconMinimize,
  IconImageGallery,
  Token,
  IconWarning,
  IconCheckCircle,
  IconChevronsRight,
  Divider,
} from '@screentone/core';
import { NavLink, useParams } from 'react-router-dom';

import useImageDetail from '../../../../hooks/useImageDetail';
import DownloadButtons from '../../../ImageDetails/DownloadButtons';
import { useConfig } from '../../../../hooks/useConfig';
import useEditImage from '../../../../hooks/useEditImage';
import { iframe } from '../../../../utils';

import { getResizeOnlyFlag } from '../../../../utils/helpers';

type ImageHeaderProps = {
  /** canCrop   */
  canEditFocalPoint: boolean;
  useOverlay?: boolean;
};

function ImageHeader({ canEditFocalPoint, useOverlay }: ImageHeaderProps) {
  const { image, publishedIdsObj, publishedId, transformationType, resetCoordinates } = useImageDetail();
  const {
    contentComponent,
    CONTENT_COMPONENT_CONST,
    setContentComponent,
    ARLock,
    setARLock,
    resetData,
    openFocalComponent,
    setOpenFocalComponent,
  } = useEditImage();
  const [localARLock, setLocalARLock] = useState<number | null>(null);
  const [isLegacySmartCrop, setIsLegacySmartCrop] = useState(transformationType === 'djSmartCrop');
  const {
    session: { property, PREVIEW_SIZES },
  } = useConfig();
  const { im: publishedIdFromUrl, type: pageType } = useParams();
  const isBatchFlow = pageType === 'batch';
  const allPublishedIds = Object.keys(publishedIdsObj);
  const isPublished = allPublishedIds.length > 0;
  const hasMultiplePublished = allPublishedIds.length > 1;

  const isResizeOnly = getResizeOnlyFlag(image, property);
  const isGif = image.format === 'gif';
  const isAnimated = (image.pages || 0) > 1;

  const cropInfo: { label: string; ar: number | null }[] = [];
  Object.keys(PREVIEW_SIZES || {}).forEach((key) => {
    if (PREVIEW_SIZES?.[key].SELECTION) {
      cropInfo.push({
        label: PREVIEW_SIZES[key].label,
        ar: PREVIEW_SIZES[key].ar,
      });
    }
  });

  useEffect(() => {
    setIsLegacySmartCrop(transformationType === 'djSmartCrop');
  }, [transformationType]);

  const isEditFocalPointDisabled =
    !canEditFocalPoint || !['cldDefault'].includes(transformationType) || !publishedIdFromUrl;

  const componentOptions = CONTENT_COMPONENT_CONST[contentComponent];

  useEffect(() => {
    setARLock(localARLock);
  }, [localARLock]);

  return (
    <Box padding={{ all: 'sm' }} style={{ borderRadius: '5px 5px 0 0' }}>
      <Group direction="row" wrap={false} valign="center" align="space-between">
        <Group direction="row" wrap={false} valign="center" align="space-between" gap="xs">
          {contentComponent ? (
            <Typography size="md">
              <Typography data-testid="edit-focal-title" weight="bold" inline margin={{ right: 'sm' }}>
                Edit {componentOptions.LABEL}
              </Typography>
              <Typography data-testid="edit-focal-description" color="asphalt" size="sm" inline>
                Move or scale the selection to change the {componentOptions.LABEL?.toLowerCase()} of the image.
              </Typography>
            </Typography>
          ) : (
            <>
              {isPublished ? (
                <Token
                  data-testid="img-status"
                  icon={IconCheckCircle}
                  color="blurple"
                  aria-label="Image Published"
                  title="This image is Published and can be used within an article"
                >
                  Published
                  {hasMultiplePublished && (
                    <Typography
                      inline
                      weight="med"
                      style={{ color: 'var(--st-token-blurple-color)' }}
                      size="sm"
                      margin={{ left: 'xs' }}
                    >
                      x{allPublishedIds.length}
                    </Typography>
                  )}
                </Token>
              ) : (
                <Alert
                  data-testid="img-status"
                  inline
                  icon={IconWarning}
                  type="warning"
                  aria-label="Image Unpublished"
                  title="This image is Unpublished and needs to be published before it can be used within an article"
                >
                  Unpublished
                </Alert>
              )}

              {isResizeOnly && (
                <Token
                  icon={IconMinimize}
                  data-testid="resize-only-token"
                  color="lava"
                  aria-label="Resize Only"
                  title="Resize Only can not be cropped"
                >
                  RESIZE ONLY
                </Token>
              )}
              {isAnimated && (
                <Token
                  icon={IconChevronsRight}
                  data-testid="animated-token"
                  color="gray"
                  aria-label="Animated Image"
                  title="Animated Images can not be cropped"
                >
                  Animated {isGif ? 'GIF' : 'Image'}
                </Token>
              )}
              {image.isDynamic && (
                <Token
                  icon={IconImageGallery}
                  data-testid="dynamic-token"
                  color="blurple"
                  aria-label="Dynamic Image"
                  title="Dynamic Images can not be cropped"
                >
                  Dynamic Image
                </Token>
              )}
              {isPublished && isLegacySmartCrop && (
                <Alert
                  data-testid="img-status"
                  margin={{ left: 'sm' }}
                  inline
                  icon={IconWarning}
                  type="warning"
                  aria-label="Legacy Smart Crop"
                  title="This image uses our legacy Smart Crop renderer."
                >
                  Legacy Smart Crop
                </Alert>
              )}
            </>
          )}
        </Group>

        <Group margin={{ right: 'xs' }} gap="sm" align="center" wrap={false}>
          {contentComponent ? (
            <>
              <Dropdown
                trigger={
                  <Group gap="xs" data-testid="free-form-btn">
                    <IconCrop /> {cropInfo?.find((item) => ARLock === item.ar)?.label || 'Free-form'}
                  </Group>
                }
                padding={{ all: 'xs' }}
                position={useOverlay ? 'left' : 'right'}
              >
                <Group gap="xs" direction="column" style={{ width: '10rem' }}>
                  <Typography variant="label3" size="sm" margin={{ all: 'none' }}>
                    Selection Aspect Ratio
                  </Typography>
                  <Divider flexItem />
                  {[{ label: 'Free-form', ar: null }, ...(cropInfo || [])].map((info) => {
                    return (
                      <Button
                        tertiary
                        fullWidth
                        active={ARLock === info.ar}
                        onClick={() => setLocalARLock(ARLock === info.ar ? null : info.ar)}
                      >
                        {info.label}
                      </Button>
                    );
                  })}
                </Group>
              </Dropdown>

              {useOverlay && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <Button
                    data-testid="apply-btn"
                    primary
                    size="sm"
                    onClick={() => {
                      if (resetCoordinates()) {
                        resetData('coordinates', componentOptions.KEY);
                      }
                      setContentComponent(false);
                      setOpenFocalComponent(false);
                    }}
                  >
                    Apply
                  </Button>
                  <Divider orientation="vertical" flexItem />
                  {iframe.isFrame() ? (
                    <Button
                      tertiary
                      onClick={() => {
                        resetData('coordinates', componentOptions.KEY);
                        setContentComponent(false);
                        setOpenFocalComponent(false);
                      }}
                    >
                      Close
                    </Button>
                  ) : (
                    <Button
                      tertiary
                      icon={IconCross}
                      onClick={() => {
                        resetData('coordinates', componentOptions.KEY);
                        setContentComponent(false);
                        setOpenFocalComponent(false);
                      }}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Button
                data-testid="edit-focal-point-btn"
                tertiary
                disabled={isEditFocalPointDisabled}
                componentEl={NavLink}
                to={!isBatchFlow && `/${property}/image/${image.asset_id}/edit/${publishedId}`}
                onClick={() => {
                  if (openFocalComponent) {
                    setContentComponent(CONTENT_COMPONENT_CONST.FOCAL_AREA.KEY);
                  } else {
                    setOpenFocalComponent(true);
                  }
                }}
                icon={IconFocalPoint}
                replace
              />
              <Divider orientation="vertical" flexItem />
              <DownloadButtons data-testid="download-btn" image={image} publishedId={publishedId} />
            </>
          )}
        </Group>
      </Group>
    </Box>
  );
}

export default ImageHeader;
