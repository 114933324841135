import React from 'react';
import { Select } from '@screentone/core';

import useEditImage from '../../../../hooks/useEditImage';

const Quality = ({ panelKey }: { panelKey: string }) => {
  const { setData, getData } = useEditImage();
  const quality = getData('quality', panelKey);

  return (
    <Select
      name="name"
      value={quality}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData('quality', e.target.value, panelKey)}
    >
      <option value="">Auto</option>
      <option value="auto:best">Best</option>
    </Select>
  );
};

export default Quality;
