import React, { useEffect } from 'react';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ImageType } from '../../types/image';
import GalleryItem from './GalleryItem';
import { Dispatch, State } from '../../hooks/useLightbox';

interface DropResult {
  name: string;
}

type DraggableGalleryItemProps = {
  /** information about image. TODO: need to pull from standard type def once API is finalized */
  image: ImageType;
  /** handler when selected */
  onSelect?: (image?: ImageType | null, e?: any) => void;
  /** a flag that indicates whether or not the image is selected in the lightbox */
  isSelected: boolean;
  /** values exported from the useLightbox hook */
  lightboxResources: {
    actions: Record<string, string>;
    dispatch: Dispatch;
    state: State;
  };
  /** all other props */
  [x: string]: any;
};

/**
 * Given an array of images, shows a row of them
 */
function DraggableGalleryItem(props: DraggableGalleryItemProps) {
  const { image, index, isSelected, onClick, dimensions, onSelect, options, lightboxResources, ...otherProps } = props;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'image',
      item: { asset_id: image.asset_id, height: image.height, index, width: image.width },
      canDrag: () => !isSelected,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResult>();

        if (item && dropResult) {
          if (typeof onSelect === 'function') {
            onSelect(image);
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [isSelected],
  );

  // hide the dragged image - we create a stack of dragged images in `CardDragLayer`
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <GalleryItem
      image={image}
      isSelected={isSelected || isDragging}
      onClick={onClick}
      dimensions={dimensions}
      onSelect={onSelect}
      ref={drag}
      {...otherProps}
    />
  );
}

export default DraggableGalleryItem;
