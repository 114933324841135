import React from 'react';
import { Helmet as Head } from 'react-helmet';

import SearchLayout from '../../../layouts/Search';
// the wrapper component with all the providers
const SearchPage = () => (
  <>
    <Head>
      <title>Images - Search</title>
    </Head>
    <SearchLayout />
  </>
);

export default SearchPage;
