import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { Alert, Group, IconError, PageWrapper, Grid } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

import useAssetManager from '../../hooks/useAssetManager';
import { useConfig } from '../../hooks/useConfig';
import useUpload from '../../hooks/useUpload';
import { validateEmptyFields, validateBulkEmptyFields } from '../../hooks/useUpload/validateEmptyFields';

import UploadNavbar from '../../components/Upload/Navbar';
import UploadBox from '../../components/Upload/UploadBox';
import UploadForm from '../../components/Upload/Form';
import UploadDynamicForm from '../../components/Upload/Form/Dynamic';
import UploadStaging from '../../components/Upload/Staging';

import { getLatestUploadFromLocalStorage, localStorageHelper } from '../../utils/helpers';
import { isFrame } from '../../utils/iframe';

import type { ImageType } from '../../types';

import styles from './Upload.module.css';

const UploadLayout = () => {
  const { actions, dispatch, state, type, onDragOver, getRootProps } = useUpload();
  const { onClick: handleBrowseFiles } = getRootProps();
  const { setLatestUploads } = useAssetManager();
  const { user } = useAuth();
  const {
    session: { property, SEARCH },
    authFetch,
  } = useConfig();

  if (!property || !SEARCH) {
    return;
  }

  const navigate = useNavigate();
  const [backToUploadList, setBackToUploadList] = useState('false');

  const backButton = () => {
    if (!location.pathname.includes('/upload')) {
      localStorageHelper.deleteItem(property);
    }

    if (!state.navigation) {
      navigate(-1);
    } else {
      dispatch({ type: actions.NAVIGATION.BACK });
    }
  };

  const publishButton = () => {
    // add files
    if (!state.navigation && state.files.accepted.length) {
      dispatch({ type: actions.NAVIGATION.FORWARD });
    }

    // upload images to cloudinary
    const djUserId = (user?.dj_user_id && user?.dj_user_id.toLowerCase()) || '';
    if (state.navigation === 1 || state.navigation === 2) {
      if (state?.files?.accepted.length === 1) {
        const image = state?.upload?.response[0];
        navigate(`/${property}/image/${state?.upload?.response[0].asset_id}`, { state: { image } });
      } else if (state?.files?.accepted.length > 1) {
        navigate(`/${property}?user=${djUserId}`);
      }
    } else if (backToUploadList === 'true') {
      navigate(`/${property}?user=${djUserId}`);
    }
    return null;
  };

  const [, hasErrors] =
    type === 'dynamic'
      ? validateBulkEmptyFields(state.bulk.validation, property, SEARCH?.OPTIONS?.FORM_VALIDATION)
      : validateEmptyFields(state.validation, property, SEARCH?.OPTIONS?.FORM_VALIDATION);
  const canPublish =
    (!state.navigation && state.files.accepted.length) ||
    backToUploadList === 'true' ||
    ((state.navigation === 1 || state.navigation === 2) &&
      state.upload.status.every((status) => status === 'complete'));

  useEffect(() => {
    // Dupe code from PageNavigation
    console.log('TODO UPDATE: UPLOAD getLatestUploadFromLocalStorage'); // eslint-disable-line no-console
    getLatestUploadFromLocalStorage(property, async (id) => await authFetch(`/api/:property/${id}`))
      .then((images: ImageType[]) => {
        setLatestUploads(images);
      })
      .catch((err) => {
        console.error('getLatestUploadFromLocalStorage: ', err);
      });
  }, [property]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => state.files.accepted.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, [state.files.accepted]);

  useEffect(() => {
    const backToUploadListValue = localStorage.getItem(`${property}:backToUploadList`) || 'false';
    setBackToUploadList(backToUploadListValue);
  }, [localStorage.getItem(`${property}:backToUploadList`)]);

  const stagingCondition = state.navigation === 1 || state.navigation === 2 || backToUploadList === 'true';

  return (
    <div
      data-withinFrame={isFrame() ? 'uploadWrapperWithoutHeader' : 'uploadWrapper'}
      className={`${isFrame() ? styles.uploadWrapperWithoutHeader : styles.uploadWrapper} ${
        onDragOver ? styles.onDragOver : ''
      }`}
    >
      <UploadNavbar
        status={state.upload.status}
        response={state.upload.response}
        onButtonLeft={state.navigation < 1 ? backButton : null}
        onButtonRight={canPublish && !hasErrors ? publishButton : null}
        stepActive={state.navigation + 1}
        stepComplete={state.navigation}
        type={type}
      />

      <div className={styles.uploadWrapperContent}>
        {!state.navigation && (
          <>
            <PageWrapper>
              <div>
                {!!state.files.rejected.length && (
                  <Grid>
                    <Grid.Row center="xs">
                      <Grid.Col md={state.files.accepted.length ? 10 : 8} xs={12}>
                        <div className={styles.errorWrapper}>
                          <Group
                            align="stretch"
                            className={styles.errorContainer}
                            direction="column"
                            data-testid="error-alert"
                            gap="sm"
                            margin={{ vertical: 'md' }}
                          >
                            {state.files.rejected.map((rejectedFile, i) => {
                              const link = rejectedFile.code.includes('image-exists')
                                ? `/${property}/image/${rejectedFile.asset_id}`
                                : '';
                              return (
                                <Alert
                                  icon={IconError}
                                  key={rejectedFile.path}
                                  onDismiss={() => {
                                    // filter out error that was dismissed
                                    dispatch({
                                      type: actions.FILES.REJECTION,
                                      payload: state.files.rejected.filter((_, j) => i !== j),
                                    });
                                  }}
                                  type={link.length ? 'primary' : 'error'}
                                >
                                  {rejectedFile.message} - {rejectedFile.filename}
                                  {link.length && (
                                    <Link to={link} target="_blank">
                                      View image
                                    </Link>
                                  )}
                                </Alert>
                              );
                            })}
                          </Group>
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid>
                )}
                {!state.files.accepted.length && backToUploadList === 'false' && (
                  <UploadBox handleBrowseFiles={handleBrowseFiles} />
                )}
                {!!state.files.accepted.length && (
                  <>
                    {type === 'dynamic' ? (
                      <UploadDynamicForm handleBrowseFiles={handleBrowseFiles} />
                    ) : (
                      <UploadForm handleBrowseFiles={handleBrowseFiles} />
                    )}
                  </>
                )}
              </div>
            </PageWrapper>
          </>
        )}

        {stagingCondition && <UploadStaging />}
      </div>
    </div>
  );
};

export default UploadLayout;
