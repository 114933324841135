import React, { useCallback, useState } from 'react';
import {
  Sidebar,
  Button,
  Group,
  IconMinusCircle,
  Typography,
  IconCaretRight,
  IconLoader,
  IconPlus,
  IconError,
  Alert,
  IconLightbox,
} from '@screentone/core';
import { useDrop } from 'react-dnd';

import useLightbox from '../../hooks/useLightbox';
import Image from '../Image';

import styles from './Lightbox.module.css';
import { helpers, iframe } from '../../utils';

import type { ImageType, PropertyType } from '../../types';

type LightboxProps = {
  /** callback when "add to story" is clicked on Lightbox */
  onAdd: (e?: Event) => any;
  /** array of images */
  images?: ImageType[];
  /** callback when a single image is removed */
  onRemove: (image: ImageType) => void;
  /** callback when "remove all" button is clicked on Lightbox */
  onRemoveAll: () => void;
  /** current status of sidebar (open/collapsed) */
  status: 'open' | 'opening' | 'collapsed' | 'collapsing';
  /** callback function when we need to open Sidebar, usually provided by `useSidebar` hook */
  openSidebar: () => void;
  /** callback function when we need to collapse Sidebar, usually provided by `useSidebar` hook */
  collapseSidebar: () => void;
  /** props to pass down to Sidebar component */
  sidebarProps?: any;
  /** PropertyType */
  property: PropertyType;
  /** IMAGE_DOMAINS */
  IMAGE_DOMAINS: {
    default: string;
    [key: string]: string;
  };
};

function Lightbox({
  onAdd,
  images,
  onRemove,
  onRemoveAll,
  status,
  openSidebar,
  collapseSidebar,
  sidebarProps = {},
  property,
  IMAGE_DOMAINS,
}: LightboxProps) {
  const [isAdding, setIsAdding] = useState(false);
  const toggleSidebar = useCallback(() => {
    if (status === 'open') collapseSidebar();
    else openSidebar();
  }, [openSidebar, collapseSidebar, status]);

  const [, drop] = useDrop(() => ({
    accept: 'image',
    drop: () => ({ name: 'Lightbox' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const { state: lightboxState } = useLightbox();
  const { publishedIdsObj } = lightboxState;

  const onAddToSource = useCallback(async () => {
    setIsAdding(true);
    await onAdd();
    setIsAdding(false);
  }, [onAdd]);

  return (
    <Sidebar {...sidebarProps} position="right" data-testid="lightbox-panel">
      {(status === 'open' || status === 'opening') && (
        <>
          <Sidebar.Header padding={{ all: 'sm' }}>
            <button className={styles.toggle} onClick={toggleSidebar} type="button" aria-label="close Lightbox">
              <Group direction="row" gap="sm" align="space-between">
                <Group direction="row" gap="sm">
                  <IconLightbox color="asphalt" />
                  <Typography color="ink" weight="bold">
                    Lightbox
                  </Typography>
                </Group>
                <IconCaretRight size="mlg" color="asphalt" />
              </Group>
            </button>
          </Sidebar.Header>
          <Sidebar.Section padding={{ vertical: 'smd', horizontal: 'md' }}>
            <div ref={drop} style={{ height: '100%' }}>
              <Group direction="column" gap="md" valign="start" data-testid="lightbox-img-group">
                {images?.map((image) => {
                  const publishedId = publishedIdsObj[image.asset_id]?.id || helpers.getLastPublished(image, property);
                  return (
                    <div
                      className={styles.image}
                      key={image.asset_id}
                      style={{
                        aspectRatio: helpers.getImageAspectRatio(image, property, publishedId),
                      }}
                    >
                      <button className={styles.image__btn} type="button" onClick={() => onRemove(image)}>
                        <Group direction="row" gap="xs">
                          <IconMinusCircle /> Remove
                        </Group>
                      </button>
                      <Image
                        src={helpers.getImageUrl({
                          publishedId,
                          image,
                          property,
                          defaultPreviewUrl: true,
                          IMAGE_DOMAINS,
                        })}
                        alt={image?.metadata?.caption || image?.metadata?.headline || 'Image without caption'}
                      />
                      {publishedId ? (
                        <Typography weight="med">{publishedIdsObj[image.asset_id]?.label || 'Default'}</Typography>
                      ) : (
                        <Alert inline icon={IconError}>
                          Will Auto Publish
                        </Alert>
                      )}
                    </div>
                  );
                })}
              </Group>
            </div>
          </Sidebar.Section>
          <Sidebar.Footer padding={{ top: 'md', horizontal: 'md', bottom: 'lg' }}>
            <Group direction="column" align="center" gap="sm">
              <Button
                data-testid="addToArticle-btn"
                icon={isAdding ? IconLoader : IconPlus}
                primary
                fullWidth
                onClick={onAddToSource}
                disabled={isAdding || !images || images.length === 0}
              >
                {iframe.getSendToSourceLabel(true)}
              </Button>

              <Button
                data-testid="removeAll-btn"
                tertiary
                icon={IconMinusCircle}
                onClick={onRemoveAll}
                disabled={isAdding || !images || images.length === 0}
              >
                Remove all from list
              </Button>
            </Group>
          </Sidebar.Footer>
        </>
      )}
      {status === 'collapsed' && (
        <Sidebar.Header padding={{ all: 'sm' }}>
          <button className={styles.toggle} onClick={toggleSidebar} type="button" aria-label="open Lightbox">
            <IconLightbox color="asphalt" />
          </button>
        </Sidebar.Header>
      )}
    </Sidebar>
  );
}

export default Lightbox;
