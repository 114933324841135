import React from 'react';
import { Route, Link, useLocation } from 'react-router-dom';
import { Subnav, Typography } from '@screentone/core';
import { AuthRouter, ProtectedRoute, LoadingPage, useAuth } from '@screentone/addon-auth-wrapper';
import isEmpty from 'lodash/isEmpty';
import GoogleAnalytics from './components/GoogleAnalytics';
import { BaseConfigProvider, useBaseConfig } from './hooks/useBaseConfig';
import { ConfigProvider } from './hooks/useConfig';
import { AssetManagerProvider } from './hooks/useAssetManager';
import { SearchProvider } from './hooks/useSearch';
import { AlertProvider } from './hooks/useAppAlerts';
import { LightboxProvider } from './hooks/useLightbox';
import { UploadProvider } from './hooks/useUpload';

import { isFrame } from './utils/iframe';

import routes from './routes';

import '@screentone/core/css/index.css';
import '@screentone/addon-auth-wrapper/css/index.css';
import '@screentone/addon-autocomplete/css/index.css';
import '@screentone/addon-calendar/css/index.css';

import styles from './App.module.css';

const Navigation = ({ children }: { children: React.JSX.Element }) => {
  const location = useLocation();
  const { currentProperty, userAccess } = useAuth();

  const pathnameArray = location.pathname.replace(/\/$/, '').split('/');
  const currentPath = pathnameArray[2];
  const isAdmin = userAccess('app_admin');

  return (
    <>
      {pathnameArray.length === 3 && isAdmin && (
        <Subnav margin={{ all: 'none' }}>
          <Subnav.Item active={currentPath === 'images'} componentEl={Link} to={`/${currentProperty}/images`}>
            Image search
          </Subnav.Item>
          <Subnav.Item active={currentPath === 'static'} componentEl={Link} to={`/${currentProperty}/static`}>
            Static Images
          </Subnav.Item>
          {/* <Subnav.Item active={currentPath === 'videos'} componentEl={Link} to={`/${currentProperty}/videos`}>
            Video
          </Subnav.Item>
          <Subnav.Item active={currentPath === 'charts'} componentEl={Link} to={`/${currentProperty}/charts`}>
            Charts
          </Subnav.Item> */}
          <Subnav.Item active={currentPath === 'collections'} componentEl={Link} to={`/${currentProperty}/collections`}>
            Collections
          </Subnav.Item>
        </Subnav>
      )}
      {children}
    </>
  );
};

const AppContent = () => {
  const { baseConfig } = useBaseConfig();

  if (isEmpty(baseConfig)) {
    return <LoadingPage />;
  }

  const Providers = ({ children }: { children: React.JSX.Element }) => (
    <ConfigProvider>
      <AssetManagerProvider>
        <SearchProvider>
          <AlertProvider>
            <UploadProvider>
              <LightboxProvider>{children}</LightboxProvider>
            </UploadProvider>
          </AlertProvider>
          <GoogleAnalytics analyticsId={baseConfig.app.analyticsId} />
        </SearchProvider>
      </AssetManagerProvider>
    </ConfigProvider>
  );

  return (
    <div className={isFrame() ? styles.im_browser_mode__iframe : styles.im_browser_mode__standalone}>
      <AuthRouter
        app={baseConfig.app}
        authConfig={baseConfig.auth}
        render={(children) => <Providers>{children}</Providers>}
        pageWrapper={Navigation}
      >
        {routes.map((route) => {
          const RouteComponent = route.component;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.protected ? (
                  <ProtectedRoute path={route.path} restricted={route.restricted}>
                    <RouteComponent />
                  </ProtectedRoute>
                ) : (
                  <RouteComponent />
                )
              }
            />
          );
        })}
      </AuthRouter>

      {isFrame() && baseConfig?.app && (
        <Typography
          componentEl="p"
          variant="note"
          align="center"
          color="asphalt"
          size="sm"
          data-testid="iframe-version"
        >
          {baseConfig.app.versions?.[0]?.name} - {baseConfig.app.versions?.[0]?.version}
          {baseConfig.app.envLabel ? ` - ${baseConfig.app.envLabel}` : ''}
        </Typography>
      )}
    </div>
  );
};

function App() {
  return (
    <BaseConfigProvider>
      <AppContent />
    </BaseConfigProvider>
  );
}
export default App;
