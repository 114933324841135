import React, { useState } from 'react';
import { Button, IconPlus, IconPlusCircle, IconLoader } from '@screentone/core';

import VariantButton, { generateItems, variantChildButtons } from '../Variant';

import { ImageType } from '../../../types';
import useConfig from '../../../hooks/useConfig';
import { createPublishedIdsObj } from '../../../utils/helpers';
import { iframe } from '../../../utils';

function AddToSourceButton({ image }: { image: ImageType }) {
  const {
    authFetch,
    session: { property, IMAGE_DOMAINS, PREVIEW_SIZES },
  } = useConfig();
  const [isDisabled, setIsDisabled] = useState(false);

  const publishedIdsObj = createPublishedIdsObj(image as ImageType, IMAGE_DOMAINS, PREVIEW_SIZES, property);
  return (
    <VariantButton
      ButtonComponentEl={Button}
      size="sm"
      primary
      label={iframe.getSendToSourceLabel()}
      data-testid="addToArticle-btn"
      icon={isDisabled ? IconLoader : IconPlus}
      items={generateItems({
        items: publishedIdsObj,
        iconTemplate: () => IconPlusCircle,
        onClickHandler: async (e, { id }) => {
          setIsDisabled(true);
          try {
            const message = await iframe.sendToSource({ image, ids: [id as string], property, authFetch });
            console.log('message: ', message);
            setIsDisabled(false);
          } catch (error) {
            console.error('sendToSource Error: ', error);
            setIsDisabled(false);
          }
        },
        otherButtonPropTemplate: () => ({ disabled: isDisabled }),
        childrenTemplate: variantChildButtons,
      })}
    />
  );
}

export default AddToSourceButton;
