import React from 'react';
import { Group, Typography, FormLabel, Checkbox } from '@screentone/core';
import { identity, pickBy } from 'lodash';

import { useConfig } from '../../../../hooks/useConfig';

import type { SearchOptionsType } from '../../../../types';

import styles from '../AdvancedSearch.module.css';

type KeywordsFilterProps = {
  advancedFilter: {
    [key: string]: string;
  };
  onSearch: (options?: SearchOptionsType) => void;
};

type Categories = {
  label: string;
  name: string;
};

const CategoriesFilter: React.FC<KeywordsFilterProps> = ({ advancedFilter, onSearch }) => {
  const {
    session: { SEARCH },
  } = useConfig();

  const categories: Categories[] = SEARCH?.OPTIONS?.categories || [];

  return (
    <Group gap="none" direction="column" margin={{ bottom: 'md' }}>
      <Group data-testid="advanced-filter-categories">
        <Typography componentEl="h3" size="md" margin={{ all: 'xs' }} weight="bold">
          Categories
        </Typography>
      </Group>

      <Group gap="none" direction="column">
        {categories.map(({ label, name }) => {
          const currentState = advancedFilter[`categories-${name}`] || false;
          const isChecked = !!currentState;

          return (
            <FormLabel
              key={name}
              label={label}
              labelPosition="right"
              margin={{ all: 'xs' }}
              data-testid="advanced-filter-suboptions-categories"
            >
              <Checkbox
                key={name}
                checked={isChecked}
                className={styles[`filter_cb_${currentState || 'none'}`]}
                onChange={() => {
                  let nextState: any = !isChecked;
                  const getNextState = () => {
                    if (!currentState) return 'include';
                    if (currentState === 'include') return 'exclude';
                    return false;
                  };
                  nextState = getNextState();
                  const newStatus = pickBy(
                    {
                      ...advancedFilter,
                      ...{ [`categories-${name}`]: nextState },
                    },
                    identity,
                  );
                  onSearch({ advancedFilter: newStatus });
                }}
              />
            </FormLabel>
          );
        })}
      </Group>
    </Group>
  );
};

export default CategoriesFilter;
