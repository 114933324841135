import React, { useState } from 'react';
import { Divider, Dropdown, Group, IconPlus, Token, Typography, Wrapper } from '@screentone/core';

import { ImageType } from '../../../types';
import { regex } from '../../../utils';
import useConfig from '../../../hooks/useConfig';
import CreateCollection from '../CreteCollection';

type FormattedImage = {
  assetId: string;
  publicId: string;
  publishedId?: string;
};

type AddToCollectionDropdownProps = {
  images: FormattedImage[];
};

const formatCollectionImage = (image: ImageType, publishedId?: string) => {
  const isImID = (publishedId && regex.id.im.test(publishedId)) || false;
  const obj: FormattedImage = {
    assetId: image.asset_id,
    publicId: image.public_id,
  };
  return isImID ? { ...obj, publishedId } : obj;
};

const AddToCollectionDropdown: React.FC<AddToCollectionDropdownProps> = ({ images }) => {
  const { authFetch } = useConfig();
  const [data, setData] = useState(null);
  const getData = (images: FormattedImage[]) => {
    console.log('get data');

    authFetch(`/api/:property/collections`)
      .then((data: any) => {
        setData(data);
      })
      .catch((err: Error) => {
        console.error('handleApplyTags Error: ', err);
      });
  };

  return (
    <Dropdown
      onToggle={(isOpening: boolean) => isOpening && getData(images)}
      trigger={
        <Group gap="xs">
          <IconPlus /> Add to Collection
        </Group>
      }
      padding={{ all: 'none' }}
    >
      {!data
        ? 'Loading...'
        : data.map((collection: any) => {
            // console.log('collection: ', collection);
            // console.log('collection.public_id: ', collection.public_id);
            // console.log('collection.metadata.headline: ', collection?.metadata?.headline);
            return (
              <>
                <Wrapper
                  padding={{ all: 'sm' }}
                  key={collection.public_id}
                  style={{ width: '200px' }}
                  onClick={() => {
                    authFetch(`/api/:property/collections/${encodeURIComponent(collection.asset_id)}`, {
                      method: 'put',
                      body: JSON.stringify({
                        images,
                      }),
                    });
                    // closeModal();
                  }}
                >
                  <Typography variant="label3">
                    {collection.metadata.headline}
                    <Token margin={{ left: 'sm' }}>{collection.related_assets.length}</Token>
                    {!['true', 'false'].includes(collection.metadata.searchable) && (
                      <Token color="blurple" margin={{ left: 'sm' }}>
                        PRIVATE
                      </Token>
                    )}
                  </Typography>
                  <Typography variant="note">{collection.metadata.caption}</Typography>
                  {collection.metadata.user && (
                    <Typography variant="note">{collection.metadata.user.display_name}</Typography>
                  )}
                </Wrapper>
                <Divider flexItem />
              </>
            );
          })}
      <CreateCollection tertiary icon={IconPlus} images={images}>
        New Collection
      </CreateCollection>
    </Dropdown>
  );
};

export default AddToCollectionDropdown;
export { formatCollectionImage };
