import Calendar, { format, parse } from '@screentone/addon-calendar';
import { Button, Dropdown, IconCalendar, IconCaretDown, Token, Typography, useDropdownState } from '@screentone/core';
import React, { useState } from 'react';

import useDateFilter from './useDateFilter';
import { constants } from '../../../utils';
import { useSearch } from '../../../hooks/useSearch';

import styles from './DateFilter.module.css';

const DateFilter = () => {
  const { open: openCalendar, setOpen: setOpenCalendar, componentRef } = useDropdownState();

  const { options, updateSearchOptions, dateFilters } = useSearch();
  const { onSelect, setTriggerText, since, until, triggerText, selectedText } = useDateFilter();
  const [presetSelected, setPresetSelected] = useState('');

  function getDifferenceInDays(date1: Date, date2: Date): number {
    // Convert both dates to milliseconds
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();

    // Calculate the difference in milliseconds
    const differenceMs = date2Ms - date1Ms;

    // Convert milliseconds to days
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    return differenceDays;
  }

  const selectFilter = (startDate: Date | null, endDate: Date | null, label: string): void => {
    onSelect({ startDate, endDate });
    setTriggerText(label);
  };

  return (
    <Dropdown
      data-testid="date-filter"
      componentRef={componentRef}
      onToggle={() => setOpenCalendar(!openCalendar)}
      open={openCalendar}
      padding={{ all: 'none' }}
    >
      <Dropdown.Trigger>
        <Button tertiary icon={IconCalendar} active={triggerText !== 'Any Time' || openCalendar}>
          {triggerText}

          <IconCaretDown style={{ marginLeft: 'var(--st-spacer-xs)' }} />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        <div className={styles.container}>
          <div className={styles.presets}>
            {Object.keys(dateFilters).map((key) => {
              const dateFilter = dateFilters[key as keyof typeof dateFilters];
              return (
                <Button
                  data-testid={`${key}-filter`}
                  active={selectedText === dateFilter.label}
                  onClick={() => {
                    setPresetSelected(key); 
                    onSelect({ startDate: dateFilter.startDate, endDate: dateFilter.endDate });
                  }}
                  tertiary
                >
                  {dateFilter.label}
                </Button>
              );
            })}
          </div>

          <div data-testid="selected-dates-text" className={styles.textContent}>
            {since ? (
              <Typography>
                Since <Token color="blurple">{format(since, constants.DATE_FORMATS.SEARCH.DATE_FILTER)}</Token> until{' '}
                <Token color="blurple">
                  {until ? format(until, constants.DATE_FORMATS.SEARCH.DATE_FILTER) : dateFilters.today.label}
                </Token>
              </Typography>
            ) : (
              <Typography> No dates selected. </Typography>
            )}
          </div>

          <Calendar
            onSelect={onSelect}
            dateRangeStart
            dateRangeEnd
            startDate={since}
            endDate={until}
            numberOfMonths={2}
            maxDate={new Date()}
            calendarBodyProps={{ style: { border: 0 } }}
          />

          <div className={styles.buttons}>
            <Button
              onClick={() => {
                const optionsDate = options.date as { since?: string; until?: string };
                if (optionsDate) {
                  onSelect({
                    startDate: optionsDate.since
                      ? parse(optionsDate.since, constants.DATE_FORMATS.CLOUDINARY, new Date())
                      : null,
                    endDate: optionsDate.until
                      ? parse(optionsDate.until, constants.DATE_FORMATS.CLOUDINARY, new Date())
                      : null,
                  });
                }
                setOpenCalendar(false);
                const untilDate = new Date(Date.parse(optionsDate.until as string));
                const currentDate = new Date();
                const sinceDate = new Date(Date.parse(optionsDate.since as string));

                const daysBetweenDates = getDifferenceInDays(untilDate, sinceDate);

                switch (daysBetweenDates) {
                  case 0:
                    if (currentDate > untilDate || currentDate > sinceDate) {
                      break;
                    }
                    selectFilter(dateFilters.today.startDate, dateFilters.today.endDate, dateFilters.today.label);
                    setPresetSelected('today');
                    break;
                  case 7:
                    selectFilter(
                      dateFilters.lastWeek.startDate,
                      dateFilters.lastWeek.endDate,
                      dateFilters.lastWeek.label,
                    );
                    setPresetSelected('lastWeek');
                    break;
                  case 30:
                    selectFilter(
                      dateFilters.lastMonth.startDate,
                      dateFilters.lastMonth.endDate,
                      dateFilters.lastMonth.label,
                    );
                    setPresetSelected('lastMonth');
                    break;
                  case 365:
                    selectFilter(
                      dateFilters.lastYear.startDate,
                      dateFilters.lastYear.endDate,
                      dateFilters.lastYear.label,
                    );
                    setPresetSelected('lastYear');
                    break;
                  default:
                    break;
                }
              }}
              secondary
            >
              Cancel
            </Button>
            <Button
              data-testid="date-filter-search-btn"
              margin={{ left: 'md' }}
              onClick={() => {
                const newSince = since ? format(since, constants.DATE_FORMATS.CLOUDINARY) : '';
                const newUntil = until ? format(until, constants.DATE_FORMATS.CLOUDINARY) : '';

                // setting label

                switch (presetSelected) {
                  case 'today':
                    selectFilter(dateFilters.today.startDate, dateFilters.today.endDate, dateFilters.today.label);
                    break;
                  case 'lastWeek':
                    selectFilter(
                      dateFilters.lastWeek.startDate,
                      dateFilters.lastWeek.endDate,
                      dateFilters.lastWeek.label,
                    );
                    break;
                  case 'lastMonth':
                    selectFilter(
                      dateFilters.lastMonth.startDate,
                      dateFilters.lastMonth.endDate,
                      dateFilters.lastMonth.label,
                    );
                    break;
                  case 'lastYear':
                    selectFilter(
                      dateFilters.lastYear.startDate,
                      dateFilters.lastYear.endDate,
                      dateFilters.lastYear.label,
                    );
                    break;
                  default:
                    selectFilter(dateFilters.anyTime.startDate, dateFilters.anyTime.endDate, dateFilters.anyTime.label);
                }

                updateSearchOptions({ 
                  ...options, 
                  date: { since: newSince, until: newUntil, range: presetSelected } 
                });
                setOpenCalendar(false);
              }}
              primary
            >
              Search
            </Button>
          </div>
        </div>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default DateFilter;
