import React, { useEffect, useState } from 'react';
import { Navigate, redirect, useLocation, useNavigate, useParams } from 'react-router-dom';

import ImageDetailsLayout from '../../../../layouts/ImageDetails';

import { useConfig } from '../../../../hooks/useConfig';
import useUpload from '../../../../hooks/useUpload';
import { ImageDetailProvider } from '../../../../hooks/useImageDetail';
import useSearch from '../../../../hooks/useSearch';
import { EditImageProvider } from '../../../../hooks/useEditImage';

const ImageDetails = () => {
  const {
    session: { property },
  } = useConfig();
  const { state, search } = useLocation();
  const params = new URLSearchParams(search);
  const cropId = params.get('cropset');
  if (cropId) {
    return <Navigate to={`/${property}/image/im-${cropId}`} replace />;
  }
  const navigate = useNavigate();
  const { id: imageIdFromUrl, im: publishedIdFromUrl } = useParams();
  const { dispatch, actions } = useUpload();
  const image = state?.image || state?.upload?.response[0];
  const [restScroll, setRestScroll] = useState(!!image);
  const { setImScrollToId } = useSearch();

  if (restScroll) {
    setImScrollToId(image.asset_id);
    setRestScroll(false);
    window.scrollTo(0, 0);
  }

  const router = {
    redirect: (url: string) => {
      redirect(url);
    },
    navigate: (url: string) => {
      navigate(url);
    },
  };

  useEffect(() => {
    // this dispatch is to clean the upload flow when we are at the Image Details Page 
    // so if the user drags in an image, it won't show up the staging page 
    // (in case the user uploaded an image previously)
    dispatch({ type: actions.ALL.RESET_UPLOAD });
  }, []);

  return (
    <ImageDetailProvider initialImage={image} imageId={imageIdFromUrl} publishedId={publishedIdFromUrl}>
      <EditImageProvider>
        <ImageDetailsLayout />
      </EditImageProvider>
    </ImageDetailProvider>
  );
};

export default ImageDetails;
