import { ImageType, PropertyType, AddToArticleType } from '../types';
import { getLastPublished } from './helpers';
import iframeSources from '../config/iframe/iframe-sources.json';
import { regex } from '.';

const isSecure = typeof window !== 'undefined' && window?.location?.protocol === 'https:';

/**
 * Get the configuration for the iframe
 * @returns object
 */
function getIframeConfig(): {
  allow_upload: any;
  button_label: string;
  data_delivery: any; 
} {
  // Get the configuration key from local storage
  const configKey = (getStorage('IM-IFRAME_SOURCE') || 'default').toLowerCase();

  // Retrieve the relevant configuration or fall back to the default
  return iframeSources[configKey as keyof typeof iframeSources] || iframeSources.default;
}


export const setEagerTransformations = async (authFetch: any, imageId: string, publishedId?: string) => {
  return await authFetch(`/api/:property/${encodeURIComponent(imageId)}/eager/${publishedId || ''}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((data: any) => {
      return { data };
    })
    .catch((error: Error) => {
      return { data: null, error };
    });
};

const getStorage = (key: string) => {
  try {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${isSecure ? '__Secure-' : ''}${key}=`));
    return cookieValue?.split('=')[1];
  } catch (error) {
    console.error('getStorage error: ', error);
    throw error;
  }
};

const setStorage = (key: string, value: string) => {
  try {
    document.cookie = `${isSecure ? '__Secure-' : ''}${key}=${value}; ${isSecure ? ' Secure;' : ''}`;
  } catch (error) {
    console.error('setStorage error: ', error);
  }
};

/**
 * Checks if the app is currently being run in an iframe
 * @returns boolean
 */
export const isFrame = () => {
  try {
    let value = window.self !== window.top;
    // @ts-ignore - Cypress
    if (!value && window.top?.cy) {
      value = !!window.frameElement && !window.frameElement.getAttribute('id')?.includes('project');
    }
    return value;
  } catch (e) {
    console.error('isFrame error: ', e);
    return false;
  }
};

/**
 * Get the label for the Send to Source button
 * @returns string - Default value is 'Add to Article'
 * */
export const getSendToSourceLabel = (addMultiple: boolean = false) => {
  const iframeConfig = getIframeConfig();
  const label = iframeConfig.button_label || 'Add to Article';
  return addMultiple ? label.replace('Add', 'Add all') : label;
}

/**
 * Checks the iframe config to see if the returned data should be an array
 * @returns boolean
 */
export const sendImageArray = () => {
  const iframeConfig = getIframeConfig();
  return isFrame() && iframeConfig.data_delivery?.list;
};

/**
 * Check the iframe config to see if the upload button should be visible
 * @returns boolean
 */
export const isUploadEnabled = () => {
  const iframeConfig = getIframeConfig();
  return isFrame() ? iframeConfig.allow_upload : true;
}

/**
 * When the app is run from an iframe, we need to track information about the parent. Add
 * information about the source to session storage.
 * @param ref - The reference to be used in the payload
 * @param source - The source of the iframe
 */
export const initializeSessionStorage = (ref: string, source: string) => {
  try {
    if (ref) {
      setStorage('IM-IFRAME_REF', ref);
    }

    if (source) {
      setStorage('IM-IFRAME_SOURCE', source.toLowerCase());
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error(error);
  }
};

type getImageTypes = {
  image: ImageType;
  id: string;
  property: PropertyType;
  ref: string | null | undefined;
  authFetch ?: any;
  format ?: string;
}
/**
 * Given an image, reformat that data into something for downstream consumption
 * @returns object
 */
const getImageData = async ({
  image,
  id,
  property,
  ref,
  authFetch,
  format
}: getImageTypes) => {
  let imageObj = {};
  
  const publishedId = regex.id.im.test(id as string) ? id : getLastPublished(image, property) || id;

  try {
    const snippetyImageObj = await authFetch(`/api/:property/snippet/image/${encodeURIComponent(id)}`);
    imageObj = snippetyImageObj?.tools?.image;
  } catch (error) {
    console.error('snippetyImageObj error using fallback ', error);
  }

  const formatMessageOverride: { ref: string | null | undefined; id: string; notes?: string;altCaption?: string;  legacy_images?: null } = {
    ref,
    id: publishedId,
  };
  if (format?.toUpperCase() === 'NP') {
    formatMessageOverride.id = publishedId?.replace(/im-/, '');
    formatMessageOverride.altCaption = '';
    formatMessageOverride.notes = '';
    formatMessageOverride.legacy_images = null;
  }

  const message: any = {
    ...imageObj,
    ...formatMessageOverride,
  };

  return message;
};


/**
 * this function combine the addToArticleFromLightbox and addToArticle
 * @param image - The image object
 * @param ids - An array of published (IM) IDs, if the image asset_id is passed the last published ID will be used
 * @param env - The environment
 * @param property - The property
 * @param appUrl - The app URL
 * @param authFetch - The auth fetch function
 * @returns object
 * 
  */
export const sendToSource = async ({
  image,
  ids = [],
  property,
  authFetch,
}: AddToArticleType ) =>  {
  const iframeConfig = getIframeConfig();
  const { data_delivery } = iframeConfig;
  
 /* This validation is for an image array when we upload multiple images with data_delivery.list.
   We use "Add to Article" for a single image during the upload stage. */
  const isArray = Array.isArray(image);

  // Function to process each image and get its data
  const processImage = async (image: ImageType, index: number = 0) => {
    const ref = getStorage('IM-IFRAME_REF');
    const data = await getImageData({
      image,
      id: ids[index],
      property,
      ref: isArray ? `${ref}_${index}` : ref,
      authFetch,
      format: data_delivery.format
    });

    await setEagerTransformations(authFetch, image.public_id, ids[index])
    return data;
  };

  // Determine the message format based on the configuration
  const message = data_delivery.list && isArray
    ? await Promise.all((image as ImageType[]).map(processImage))
    : await processImage(image as ImageType);
    

  console.groupCollapsed('Posting Image Data Payload'); // eslint-disable-line no-console
    console.log('ref: ', getStorage('IM-IFRAME_REF')); // eslint-disable-line no-console
    console.log('JSON: ', message); // eslint-disable-line no-console
    console.groupCollapsed('STRING:'); // eslint-disable-line no-console
      console.log(JSON.stringify(message, null, 2)); // eslint-disable-line no-console
    console.groupEnd(); // eslint-disable-line no-console
  console.groupEnd(); // eslint-disable-line no-console
  
  // Process eager transformations if applicable
  // const imagesToProcess = data_delivery.list  && isArray ? image as ImageType[] : [image as ImageType];
  // console.log('imagesToProcess: ', imagesToProcess);
  // authFetch && imagesToProcess.forEach(image => 
  //   setEagerTransformations(authFetch, image.public_id)
  // );

  // Post the message to the parent window or opener
  if (window.parent || window.opener) {
    (window.parent || window.opener).postMessage(JSON.stringify(message), '*');
  }

  return message;
};
