import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, FormLabel, IconCaretDown, IconDatabase, Group } from '@screentone/core';
import { useConfig } from '../../../hooks/useConfig';

import type { SearchOptionsType } from '../../../types';

type SourcesFilterProps = {
  currentOptions: SearchOptionsType;
  onSearch: (options: SearchOptionsType) => void;
};

const SourcesFilter: React.FC<SourcesFilterProps> = ({ currentOptions, onSearch }) => {
  const {
    session: { SEARCH },
  } = useConfig();

  const sources = SEARCH?.OPTIONS?.sources;

  if (Object.keys(sources || {})?.length <= 1) {
    return <></>;
  }

  const [sourceStates, setSourceStates] = useState<string[]>(currentOptions?.sourceFilter || []);

  useEffect(() => {
    setSourceStates(currentOptions.sourceFilter || []);
  }, [currentOptions]);

  const handleSourceToggle = (sourceKey: string) => {
    const newSourceState = sourceStates.includes(sourceKey)
      ? sourceStates.filter((key) => key !== sourceKey)
      : [...sourceStates, sourceKey];

    setSourceStates(newSourceState);

    onSearch({ sourceFilter: newSourceState });
  };

  return (
    <Dropdown data-testid="sources-filter-btn" padding={{ all: 'none' }}>
      <Dropdown.Trigger>
        <Button tertiary icon={IconDatabase} active={sourceStates.length > 0}>
          Sources
          <IconCaretDown style={{ marginLeft: 'var(--st-spacer-xs)' }} />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        style={{
          boxSizing: 'border-box',
          minWidth: '15rem',
        }}
        padding={{ all: 'none' }}
      >
        <Group gap="xs" margin={{ all: 'sm' }} direction="column">
          {Object.keys(sources).map((key: string) => (
            <FormLabel
              data-testid="sources-checkbox"
              label={sources[key].label}
              labelPosition="right"
              fullWidth
              key={sources[key].label}
            >
              <Checkbox checked={sourceStates.includes(key) || false} onChange={() => handleSourceToggle(key)} />
            </FormLabel>
          ))}
          <Group.Item fullWidth>
            <Button
              data-testid="clear-published-status"
              size="sm"
              tertiary
              onClick={() => {
                setSourceStates([]);
                onSearch({ sourceFilter: [] });
              }}
            >
              Clear
            </Button>
          </Group.Item>
        </Group>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default SourcesFilter;
