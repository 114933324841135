import React, { Dispatch, SetStateAction, createContext, useContext, useState, useEffect } from 'react';
import { LoadingPage, ErrorPage } from '@screentone/addon-auth-wrapper';

import CustomErrorMessage from '../../components/CustomErrorMessage';

import { getTransformationType, createPublishedIdsObj, getLastPublished } from '../../utils/helpers';
import { regex } from '../../utils';

import useConfig from '../useConfig';

import type { ActivityType, ImageType, PropertyType, TransformationType } from '../../types';

type publicImageObject = {
  [key: string]: {
    id: string;
    label: string;
    url: string;
    previewSizes: { [key: string]: { ar: number; label: string; url: string } };
  };
};

const ImageDetailContext =
  createContext<
    | {
        image: ImageType & { [key: string]: any };
        imageId: string;
        setImage: (image: ImageType) => void;
        getImageEmbeddedMetadata: () => any;
        publishedId?: string;
        setPublishedId: (publishedId: string) => void;
        hasFetchedImage: boolean;
        transformationType: TransformationType;
        deleteDerived: (derivedId: string) => void;
        activityLogs: ActivityType[] | undefined;
        setActivityLogs: Dispatch<SetStateAction<ActivityType[] | undefined>>;
        publishedIdsObj: publicImageObject;
        setLocalContext: Dispatch<SetStateAction<{ [key: string]: any }>>;
        localContext: { [key: string]: any };
        resetCoordinates: () => boolean;
      }
    | undefined
  >(undefined);

type ImageDetailProviderArguments = {
  initialImage: ImageType;
  imageId?: string;
  publishedId?: string;
  children?: React.ReactNode;
};

export function ImageDetailProvider({
  initialImage,
  imageId: initialImageId,
  publishedId: initialPublishedId = '',
  children,
}: ImageDetailProviderArguments) {
  const {
    authFetch,
    session: { env, property, IMAGE_DOMAINS, PREVIEW_SIZES },
  } = useConfig();

  const isImID = regex.id.im.test(initialPublishedId);
  const [image, setImage] = useState<ImageType>(initialImage);
  const [activityLogs, setActivityLogs] = useState<ActivityType[] | undefined>();
  const [imageError, setImageError] = useState<Error | undefined>();
  const [hasFetchedImage, setHasFetchedImage] = useState(false);
  const [isFetchingImage, setIsFetchingImage] = useState(false);
  const [publishedIdsObj, setPublishedIdsObj] = useState<publicImageObject>({});

  const [publishedId, setPublishedId] = useState<string>(isImID ? initialPublishedId : Object.keys(publishedIdsObj)[0]);
  const initialTransformationType = getTransformationType(image, property, publishedId);
  const [transformationType, setTransformationType] = useState(initialTransformationType);

  const [localContext, setLocalContext] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (image) {
      const lastPublished = publishedId ? publishedId : getLastPublished(image as ImageType, property as PropertyType);
      if (!publishedId && lastPublished !== null) {
        setPublishedId(lastPublished);
      }
      const transformationType = getTransformationType(image, property as PropertyType, lastPublished);
      setTransformationType(transformationType);
      setPublishedIdsObj(createPublishedIdsObj({ ...image }, IMAGE_DOMAINS, PREVIEW_SIZES, property, localContext));
    }
  }, [image, publishedId, property, env, localContext]);

  useEffect(() => {
    if (!image && !hasFetchedImage && !isFetchingImage) {
      setIsFetchingImage(true);
      authFetch(`/api/:property/${encodeURIComponent(initialImageId as string)}`)
        .then((img: ImageType) => {
          setImage(img);
          setHasFetchedImage(true);
        })
        .catch((err: Error) => {
          console.error('Error fetching image', err);
          setImageError(err);
        })
        .finally(() => {
          setIsFetchingImage(false);
        });
    }
  }, [hasFetchedImage, image, initialImageId]);

  const setImageCallback = (newImgObj: ImageType) => {
    setPublishedIdsObj(createPublishedIdsObj({ ...image, ...newImgObj }, IMAGE_DOMAINS, PREVIEW_SIZES, property));

    const updatedImage = { ...image, ...newImgObj };
    if (updatedImage && updatedImage.additional_resources?.length === 0 && image.additional_resources) {
      updatedImage.additional_resources = image.additional_resources;
    }

    setImage(updatedImage);
    setHasFetchedImage(true);
  };

  const getImageEmbeddedMetadata = () => {
    if (image.image_metadata) return image.image_metadata;

    if (!isFetchingImage && !image.image_metadata) {
      setIsFetchingImage(true);
      authFetch(`/api/:property/${encodeURIComponent(image.public_id as string)}`)
        .then((img: ImageType) => {
          setImage(img);
          return img.image_metadata;
        })
        .catch((err: Error) => {
          console.error('Error fetching image', err);
          setImageError(err);
        })
        .finally(() => {
          setIsFetchingImage(false);
        });
    }
  };

  const deleteDerived = (derivedId: string) => {
    image?.derived?.find((img: any, i: number) => {
      if (img.id === derivedId && image?.derived) {
        image.derived.splice(i, 1);
        setImage(image);
        return true;
      }
      return false;
    });
  };

  if (imageError && 'status' in imageError && imageError.status === 404) {
    return (
      <CustomErrorMessage
        title="Image Not Found or Removed"
        message="This image does not exist or has been removed. "
        illustration="empty"
      />
    );
  }
  if (imageError && !image) {
    return <ErrorPage type={'status' in imageError ? (imageError.status as number) : '500'} />;
  }

  if (!image) {
    return <LoadingPage />;
  }

  const resetCoordinates = () => {
    const localContextFocalArea = localContext[`coordinates_${publishedId}`];
    const localContextReframe = localContext[`crop_${publishedId}`];
    const localContextThumbnailZoom = localContext[`gravity_thumb_${publishedId}`];
    if (
      localContextFocalArea &&
      localContextFocalArea.split(',')[2] === '0' &&
      localContextFocalArea.split(',')[3] === '0'
    ) {
      return true;
    } else if (
      localContextReframe &&
      localContextReframe.split(',')[2] === '0' &&
      localContextReframe.split(',')[3] === '0'
    ) {
      return true;
    } else if (
      localContextThumbnailZoom &&
      localContextThumbnailZoom.split('_')[3] === '0' &&
      localContextThumbnailZoom.split('_')[4] === '0'
    ) {
      return true;
    }
    return false;
  };

  return (
    <ImageDetailContext.Provider
      value={{
        image,
        imageId: image.asset_id,
        setImage: setImageCallback,
        getImageEmbeddedMetadata,
        hasFetchedImage,
        publishedId,
        setPublishedId,
        publishedIdsObj,
        transformationType,
        deleteDerived,
        setLocalContext,
        activityLogs,
        setActivityLogs,
        localContext,
        resetCoordinates,
      }}
    >
      {children}
    </ImageDetailContext.Provider>
  );
}

const useImageDetail = () => {
  const context = useContext(ImageDetailContext);

  if (context === undefined) {
    throw new Error('useImageDetail must be used within a ImageDetailProvider');
  }

  return context;
};

export default useImageDetail;
