import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  PageWrapper,
  List,
  IconFolder,
  IconCaretDown,
  IconUpload,
  IconTrash,
  Group,
  useModalState,
  Input,
  Typography,
  IconFileText,
} from '@screentone/core';
import set from 'lodash/set';
import _merge from 'lodash/merge';

import useConfig from '../../../hooks/useConfig';

import { slugNormalize } from '../../../utils/helpers';
import { Link } from 'react-router-dom';

const StaticImage = () => {
  const {
    authFetch,
    session: { IMAGE_DOMAINS, property },
  } = useConfig();

  const baseFolders = {
    folders: {
      common: { name: 'common', path: 'common' },
      scripts: { name: 'scripts', path: 'scripts', readOnly: true },
      [property]: {
        name: property,
        path: `${property}`,
      },
    },
  };

  const [folder, setFolderFn] = useState<string | undefined>(undefined);
  const [data, setData] = useState(baseFolders);
  const [newFolder, setNewFolder] = useState<string | undefined>(undefined);
  const [newFolderInput, setNewFolderInput] = useState<string>('');
  const [uploadFolder, setUploadFolder] = useState<string>('');
  const [uploadFiles, setUploadFiles] = useState<File[] | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { open, openModal, closeModal } = useModalState();
  const { open: openUpload, openModal: openModalUpload, closeModal: closeUploadModal } = useModalState();

  const setFolder = (path: string) => {
    console.log('-------------------');
    console.log(`fetching data for ${path}`);

    setFolderFn(path);
    authFetch(`/api/:property/static/${encodeURIComponent(path)}`)
      .then((res: any) => {
        console.log('res: ', res);
        const objectPath = path.replaceAll('/', '.folders.');

        const newObject = set({}, objectPath, res);
        console.log('newObject: ', newObject);

        const newData = _merge({}, data, { folders: newObject });
        console.log('newData: ', path, newData);
        setData(newData);
      })
      .catch((err: Error) => {
        console.error('handleApplyTags Error: ', err);
        // setData({ ...data, [path]: { error: err } });
      });
  };

  const getFolderList = (
    folderData: { [x: string]: any; folders: { [x: string]: any }; resources?: {} },
    path?: string,
  ) => {
    return (
      <>
        {folderData?.folders &&
          Object.keys(folderData?.folders).map((key) => {
            const folder = folderData?.folders[key];
            const isOpen = path?.startsWith(folder.path);

            return (
              <List.Item
                fullWidth
                key={folder.name}
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Group fullWidth align="space-between">
                  <Group.Item grow={1}>
                    <Button
                      fullWidth
                      tertiary
                      active={folder.path === path}
                      icon={isOpen ? IconCaretDown : IconFolder}
                      onClick={() => setFolder(folder.path)}
                    >
                      {folder.name}
                    </Button>
                  </Group.Item>
                  <Group.Item>
                    <Button
                      tertiary
                      icon={IconFolder}
                      disabled={folder.readOnly}
                      onClick={() => {
                        console.log('New Folder', folder.path);
                        setNewFolder(folder.path);
                        openModal();
                      }}
                    />
                    <Button
                      tertiary
                      icon={IconUpload}
                      disabled={folder.readOnly}
                      onClick={() => {
                        console.log('upload', folder.path);
                        setUploadFolder(folder.path);
                        openModalUpload();
                      }}
                    />
                  </Group.Item>
                </Group>
                {isOpen && (
                  <List margin={{ left: 'lg' }} fullWidth>
                    {getFolderList(folder, path)}
                  </List>
                )}
              </List.Item>
            );
          })}
        {folderData?.resources &&
          Object.keys(folderData?.resources).map((key) => {
            const item = folderData.resources[key];
            const publicImageDomain = IMAGE_DOMAINS?.default;
            const publicUrl = item.public_id

              .replace(`${property}/`, publicImageDomain)
              .replace('common/static', `${publicImageDomain}static/common`);

            console.log('item.public_id: ', item.public_id);
            console.log('publicUrl: ', publicUrl);
            return (
              <List.Item fullWidth key={key}>
                <div style={{ width: '100px', height: '75px', display: 'flex', marginRight: '16px' }}>
                  {item.resource_type === 'image' ? (
                    <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={item.secure_url} alt={key} />
                  ) : (
                    <IconFileText size="lg" />
                  )}
                </div>
                <div>
                  <strong>{item.public_id.replace(`${item.folder}/`, '')}</strong>
                  <br />
                  {item.format}
                  {item.resource_type === 'image' ? ` - ${item.width}x${item.height}` : ''}
                  <br />
                  <Link to={publicUrl} target="_blank">
                    {publicUrl}
                  </Link>
                </div>
                <Button
                  tertiary
                  color="lava"
                  icon={IconTrash}
                  onClick={({ target }: { target: HTMLButtonElement }) => {
                    target.setAttribute('disabled', 'true');
                    target.setAttribute('aria-disabled', 'true');
                    authFetch(
                      `/api/:property/${encodeURIComponent(item.public_id)}/deleteCache/${item.type}/${
                        item.resource_type
                      }`,
                      {
                        method: 'DELETE',
                      },
                    )
                      .then((res) => {
                        return res.json();
                      })
                      .then((data) => {
                        console.log(`Cached Cleared for ${item.public_id}: `, data);
                      })
                      .catch((err) => console.error(err))
                      .finally(() => {
                        target.removeAttribute('disabled');
                        target.removeAttribute('aria-disabled');
                      });
                  }}
                >
                  Cache
                </Button>
              </List.Item>
            );
          })}
      </>
    );
  };

  const acceptImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const newFiles = e.target.files;
    const filesList = e.target.files;

    const newFiles: File[] = uploadFiles ? Array.from(uploadFiles) : [];

    if (filesList) {
      Array.from(filesList).forEach((file) => {
        const local_url = URL.createObjectURL(file);
        console.log('local_url: ', file.name, local_url);
        if (file) newFiles.push(new File([file], file.name.toLowerCase()));
      });
    }
    console.log('newFiles: ', newFiles);

    setUploadFiles(newFiles);
  };

  const uploadImages = () => {
    console.log('uploadImages: ', uploadFiles);
    if (uploadFiles) {
      setIsUploading(true);
      const uploadedFiles: any[] = [];
      authFetch(`/api/:property/static/${encodeURIComponent(uploadFolder)}`, {
        method: 'POST',
      })
        .then((res: any) => {
          console.log('res: ', res);
          const {
            formData, // Do not modify this object
            uploadUrl, // The URL to upload the image to
          } = res;

          Array.from(uploadFiles).forEach(async (file, idx) => {
            uploadFiles[idx].status = 'uploading';
            const body = new FormData();
            body.append('file', file as File);
            Object.entries(formData).forEach(([key, value]) => {
              body.append(key, value as string);
            });
            const cldRequest = new Request(uploadUrl, { method: 'POST', body });

            await fetch(cldRequest)
              .then((cldResponse) => {
                return cldResponse.json();
              })
              .then((cldResult) => {
                console.log('cldResult: ', cldResult);
                uploadFiles[idx].status = 'complete';

                uploadedFiles.push(cldResult);
              })
              .catch((error) => console.error(error));
          });

          // const formData = new FormData();
          // for (let i = 0; i < uploadFiles.length; i++) {
          //   formData.append('files', uploadFiles[i]);
          // }
        })
        .catch((err: Error) => {
          console.error('handleApplyTags Error: ', err);
        })
        .finally(() => {
          const objectPath = uploadFolder.replaceAll('/', '.folders.');
          const newObject = set({}, objectPath, { resources: uploadedFiles });
          const newData = _merge({}, data, { folders: newObject });

          setData(newData);
          closeUploadModal();
          setIsUploading(false);
        });
    }
  };

  return (
    <PageWrapper padding={{ top: 'mlg' }}>
      <Typography variant="header2">Static Images</Typography>
      <Box>
        <List key={folder} fullWidth>
          {getFolderList(data, folder)}
        </List>
      </Box>

      {open && (
        <Dialog
          aria-labelledby="dialogTitle"
          aria-describedby="dialogDesc"
          onDismiss={closeModal}
          status={open ? 'open' : 'closed'}
        >
          <Dialog.Title id="dialogTitle">New Folder</Dialog.Title>
          <Dialog.Content id="dialogDesc">
            <Group direction="row" valign="center" gap="sm">
              <Group.Item>
                <Typography variant="code">{newFolder}/</Typography>
              </Group.Item>
              <Group.Item>
                <Input
                  value={newFolderInput}
                  onChange={(e: { target: { value: string } }) =>
                    setNewFolderInput(slugNormalize(e.target.value.toLowerCase()))
                  }
                />
              </Group.Item>
            </Group>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              secondary
              onClick={() => {
                setNewFolderInput('');
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              primary
              onClick={() => {
                console.log('Create', newFolder, newFolderInput);
                authFetch(
                  `/api/:property/static/createfolder/${encodeURIComponent(`${newFolder}/${newFolderInput}`)}`,
                  {
                    method: 'POST',
                  },
                )
                  .then((res: any) => {
                    console.log('res: ', res);
                    const objectPath = res.path.replaceAll('/', '.folders.');
                    const newObject = set({}, objectPath, {
                      name: res.name,
                      path: res.path,
                    });
                    const newData = _merge({}, data, { folders: newObject });

                    setData(newData);
                    setFolder(res.path);
                  })
                  .catch((err: Error) => {
                    console.error('handleApplyTags Error: ', err);
                  })
                  .finally(() => {
                    setNewFolderInput('');
                    closeModal();
                  });
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}

      {openUpload && (
        <Dialog
          aria-labelledby="dialogTitle"
          aria-describedby="dialogDesc"
          onDismiss={closeUploadModal}
          status={openUpload ? 'open' : 'closed'}
          size="xl"
          key={Object.keys(uploadFiles || {})?.length}
        >
          {console.log('Object.keys(uploadFiles || {})?.length: ', Object.keys(uploadFiles || {})?.length)}
          <Dialog.Title id="dialogTitle">Upload to {uploadFolder}</Dialog.Title>
          <Dialog.Content id="dialogDesc">
            <Input type="file" accept=".jpg, .jpeg, .png, .svg, .gif" multiple onChange={acceptImages} />
            {uploadFiles && Object.keys(uploadFiles || {})?.length && (
              <List>
                {Array.from(uploadFiles).map((file) => {
                  console.log('file: ', file);
                  const local_url = URL.createObjectURL(file);
                  console.log('List local_url: ', file.name, local_url);
                  return (
                    <List.Item key={file.name}>
                      <div style={{ width: '100px', height: '75px', display: 'flex', marginRight: '16px' }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={local_url} alt={file.name} />
                      </div>

                      <div>
                        <strong>
                          {file.status === 'uploading' ? '⏳ ' : ''}
                          {file.status === 'complete' ? '✅ ' : ''}
                          {file.name}
                        </strong>
                        <br />
                        {file.type}
                      </div>
                    </List.Item>
                  );
                })}
              </List>
            )}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              secondary
              disabled={isUploading}
              onClick={() => {
                closeUploadModal();
                setUploadFolder('');
              }}
            >
              Cancel
            </Button>
            <Button primary disabled={isUploading} onClick={() => uploadImages()}>
              Upload
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </PageWrapper>
  );
};

export default StaticImage;
