import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  useDropdownState,
  Group,
  IconPlusCircle,
  IconTag,
  IconInfo,
  Input,
  Token,
  Tooltip,
  Typography,
  FormHelperText,
  useModalState,
  Dialog,
} from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import useConfig from '../../hooks/useConfig';
import { handleApplyTags } from '../../utils/helpers';

import type { ImageType } from '../../types';
import useAlert from '../../hooks/useAppAlerts';

interface DescriptionProps {
  image: ImageType;
  setImage?: (image: ImageType) => void;
  disabled?: boolean;
}

function Tags({ image, setImage, disabled }: DescriptionProps) {
  const {
    authFetch,
    session: { property },
  } = useConfig();

  const { userAccess } = useAuth();
  const { setAlert } = useAlert();
  const canPublish = userAccess(`${property}.publish`);

  const [formattedTag, setFormattedTag] = useState('');
  const [savingTag, setSavingTag] = useState<Boolean | { error: string }>(false);
  const { open, setOpen, componentRef } = useDropdownState();
  const [tags, setTags] = useState(image.tags?.filter((tag) => tag.startsWith(`${property.toUpperCase()}#TAG#`)));
  const tagButtonRef = useRef<HTMLDivElement>(null);
  const [tagPosition, setTagPosition] = useState('left');
  const { open: isModalOpen, openModal, closeModal } = useModalState();
  const [tagToDelete, setTagToDelete] = useState<string | null>(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setTags(image.tags?.filter((tag) => tag.startsWith(`${property.toUpperCase()}#TAG#`)));
  }, [image.tags]);

  useEffect(() => {
    if (tagButtonRef.current) {
      const { right } = tagButtonRef.current.getBoundingClientRect();
      const pageWidth = document.documentElement.clientWidth;
      setTagPosition(pageWidth - (right + 180) > 0 ? 'left' : 'right');
    }
  }, [tagButtonRef.current, document.documentElement.clientWidth]);

  const handleApplyTagsHandler = (publicId: string, tags: string[], method: string) => {
    return new Promise((resolve, reject) => {
      handleApplyTags(publicId, tags, authFetch, method)
        .then((data: any) => {
          resolve(data);
          let updateTagList = [];
          if (method === 'POST') {
            updateTagList = image.tags ? [...image.tags, ...tags] : [tags];
          } else {
            updateTagList = image.tags ? image.tags?.filter((tag) => !tags.includes(tag)) : [tags];
          }
          if (setImage && updateTagList) {
            const updatedImage = { ...(image as ImageType), ...{ tags: updateTagList } };
            setImage(updatedImage as ImageType);
          }
        })
        .catch((err: Error) => {
          console.error('handleApplyTags Error: ', err);
          reject(err);
        });
    });
  };

  const handleDelete = (tagToDelete: string) => {
    setDeleting(true);
    handleApplyTagsHandler(image.public_id, [tagToDelete], 'DELETE')
      .then(() => {
        const index = tags?.indexOf(tagToDelete);
        const updatedTags = [...(tags || [])];
        if (index !== undefined && index !== -1) {
          updatedTags.splice(index, 1);
        }
        setTags(updatedTags || []);
        setDeleting(false);
        closeModal();
      })
      .catch((error) => {
        console.error('Error deleting tag: ', error.message);
        setDeleting(false);
        closeModal();
        setAlert('Error deleting tag', { type: 'error' });
      });
  };

  return (
    <Group direction="row" gap="xs" align="start" componentEl="dl" margin={{ all: 'none' }}>
      <Group gap="xs">
        <Typography size="sm" inline>
          <Typography inline>
            {/* <Typography  variant="label3" inline>Tags</Typography>{' '} */}
            <Tooltip>
              <Tooltip.Content position="bottom" style={{ width: '360px', left: '180px' }}>
                <Typography size="sm" margin={{ bottom: 'sm' }} style={{ color: 'var(--st-color-cool)' }}>
                  Tags are keywords that help you find and organize your images. Tags can only contain letters, numbers,
                  and underscores. Spaces and special characters will be removed. Tags are not visible to the public.
                </Typography>
                <Typography size="sm" margin={{ bottom: 'sm' }} style={{ color: 'var(--st-color-cool)' }}>
                  To search for images with a specific tag, use the search bar and type the tag name with a hashtag
                  (e.g. #tagname).
                </Typography>
                <Typography size="sm" style={{ color: 'var(--st-color-cool)' }}>
                  <em>Note: this is a BETA feature and is subject to change.</em>
                </Typography>
              </Tooltip.Content>
              <Tooltip.Trigger aria-describedby="tooltipContent">
                <Typography variant="label3" inline>
                  Tags
                </Typography>
                <IconInfo
                  size="smd"
                  color="asphalt"
                  margin={{ left: 'xs', right: 'xs' }}
                  style={{ verticalAlign: 'middle' }}
                />
              </Tooltip.Trigger>
            </Tooltip>
          </Typography>
        </Typography>
      </Group>

      <div ref={tagButtonRef}>
        <Group gap="xs">
          {tags?.map((tag) => (
            <Token
              color="gray"
              onDismiss={() => {
                setTagToDelete(tag);
                openModal();
              }}
            >
              {tag.split('#TAG#')[1]}
            </Token>
          ))}
          {canPublish && (
            <Dropdown open={open} position={tagPosition} componentRef={componentRef} disabled={disabled}>
              <Dropdown.Trigger>
                <Button icon={IconPlusCircle} tertiary size="sm" />
              </Dropdown.Trigger>
              <Dropdown.Content padding={{ all: 'none' }}>
                <Input
                  icon={IconTag}
                  placeholder="Enter a tag"
                  value={formattedTag}
                  style={{ width: '180px' }}
                  disabled={typeof savingTag === 'boolean' && savingTag}
                  onToggle={() => {
                    setOpen(!open);
                  }}
                  onBlur={({ target }: { target: HTMLInputElement }) => {
                    const text = target.value.replace(/[^0-9a-z_-]/gi, '').toUpperCase();
                    setFormattedTag(text);
                  }}
                  onChange={({ target }: { target: HTMLInputElement }) => {
                    const text = target.value.replace(/[^0-9a-z_-]/gi, '').toUpperCase();
                    setFormattedTag(text);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      setSavingTag(true);
                      const tagWithPrefix = `${property.toUpperCase()}#TAG#${formattedTag}`;

                      if (tags?.includes(tagWithPrefix)) {
                        setSavingTag(false);
                        setSavingTag({ error: 'Tag already exists' });
                        setFormattedTag('');
                      } else if (formattedTag.length > 32) {
                        setSavingTag(false);
                        setSavingTag({ error: 'Tag must be less than 32 characters' });
                        setFormattedTag('');
                      } else if (formattedTag.length === 0) {
                        setSavingTag(false);
                        setSavingTag({ error: 'Please enter a tag' });
                        setFormattedTag('');
                      } else {
                        handleApplyTagsHandler(image.public_id, [tagWithPrefix], 'POST')
                          .then(() => {
                            setSavingTag(false);
                            setFormattedTag('');
                            setOpen();
                            setTags([...(tags || []), tagWithPrefix]);
                          })
                          .catch((error) => {
                            setSavingTag({ error: error.message });
                          });
                      }
                    }
                  }}
                />
                {!savingTag && (
                  <FormHelperText margin={{ left: 'sm', bottom: 'sm', top: 'none' }}>Hit Enter to save</FormHelperText>
                )}
                {savingTag && typeof savingTag === 'boolean' && (
                  <FormHelperText margin={{ left: 'sm', bottom: 'sm', top: 'none' }}>Saving...</FormHelperText>
                )}
                {savingTag && typeof savingTag !== 'boolean' && 'error' in savingTag && (
                  <FormHelperText error margin={{ left: 'sm', bottom: 'sm', top: 'none' }}>
                    Error Saving Tag
                  </FormHelperText>
                )}
              </Dropdown.Content>
            </Dropdown>
          )}
          {isModalOpen && tagToDelete && (
            <Dialog onDismiss={closeModal} status={isModalOpen ? 'open' : 'closed'} size="fluid">
              <Dialog.Title id="dialogTitle">Confirm Delete</Dialog.Title>
              <Dialog.Content>
                <Typography variant="header3">
                  <strong>Delete Tag?</strong>
                </Typography>
                <Typography size="sm">
                  Are you sure you want to delete the tag <strong>{`${tagToDelete.split('#TAG#')[1]}`}</strong>?
                </Typography>
              </Dialog.Content>
              <Dialog.Actions>
                <Button secondary onClick={closeModal} disabled={deleting}>
                  Cancel
                </Button>
                <Button
                  primary
                  onClick={() => {
                    handleDelete(tagToDelete);
                  }}
                  disabled={deleting}
                >
                  Delete
                </Button>
              </Dialog.Actions>
            </Dialog>
          )}
        </Group>
      </div>
    </Group>
  );
}

export default Tags;
