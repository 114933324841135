import React, { useState } from 'react';
import { Button, IconCopy, Group, IconCheckCircle, IconLoader } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { useNavigate } from 'react-router-dom';

import useAlert from '../../hooks/useAppAlerts';
import { useConfig } from '../../hooks/useConfig';

import CopyToClipboard from '../CopyToClipboard';
import AddToSourceButton from '../Buttons/AddToSource';
import VariantButton, { generateItems } from '../Buttons/Variant';

import { checkIfPublished, getLastPublished } from '../../utils/helpers';
import { getCopyMetadata } from '../../utils/getCopyMetadataText';

import type { ImageType, PropertyType } from '../../types';

import styles from './ActionButtons.module.css';

type ActionButtonsTypes = {
  /** object representing image's data */
  image: ImageType;
  /** event handler when "add to article" is clicked, also controls whether it's shown or not */
  showAddToSource?: boolean;
  /** setImage helper */
  setImage?: ((res: ImageType) => void) | null;

  publishedIdsObj?: { [key: string]: { id: string; label: string; url: string } };
};

const variantChildButtons = (item: any, type: 'dropdown' | 'dialog') => {
  if (type === 'dialog') {
    return item.id;
  }
  return (
    <Group wrap={false} gap="sm" align="space-between" fullWidth>
      <Group.Item>{item.label}</Group.Item>
      <Group.Item>{item.id}</Group.Item>
    </Group>
  );
};

function CopyMetadataButton({
  image,
  publishedIdsObj,
}: {
  image: ImageType;
  publishedIdsObj: { [key: string]: { id: string; label: string; url: string } };
}) {
  const {
    session: { appUrl, property },
  } = useConfig();
  const { user } = useAuth();

  return (
    <VariantButton
      data-testid="copy-img-metadata-btn"
      ButtonComponentEl={CopyToClipboard}
      tertiary
      label="Metadata"
      icon={IconCopy}
      items={generateItems({
        items: publishedIdsObj,
        iconTemplate: () => IconCopy,
        otherButtonPropTemplate: ({ id }) => {
          const data = getCopyMetadata({
            appUrl,
            user: user || {},
            property,
            image,
            publishedId: id !== 'unpublished' ? id : null,
          });
          return {
            text: {
              text: data[0],
              html: data[1],
            },
          };
        },
        childrenTemplate: variantChildButtons,
      })}
    />
  );
}
function PublishButton({
  image,
  setImage,
  authFetch,
  property,
}: {
  image: ImageType;
  setImage: any;
  authFetch: any;
  property: PropertyType;
}) {
  const { setAlert } = useAlert();
  const { userAccess } = useAuth();
  const navigate = useNavigate();
  const canPublish = userAccess(`${property}.publish`);
  const [isPublishDisabled, setPublishDisabled] = useState(!canPublish);

  return (
    <Button
      data-testid="publish-image-btn"
      icon={canPublish && isPublishDisabled ? IconLoader : IconCheckCircle}
      size="sm"
      primary
      className={canPublish && isPublishDisabled ? styles.action_button : ''}
      disabled={isPublishDisabled}
      onClick={() => {
        setPublishDisabled(true);
        authFetch(`/api/:property/${encodeURIComponent(image.public_id)}/publish`, {
          method: 'POST',
        })
          .then((res: any) => {
            const error = res.error || res.data?.error;
            if (error?.status) {
              throw new Error(`${error.status} - Error publishing image`);
            }
            if (setImage) {
              setImage(res);
            }
            setPublishDisabled(false);

            setAlert('Image Published', { type: 'success', icon: IconCheckCircle });
            const publishedId = getLastPublished(res, property);
            navigate(`/${property}/image/${image.asset_id}/edit/${publishedId}`);
          })
          .catch((error: Error) => {
            console.error('publishImage error: ', error);
            setAlert(error.message || 'Error publishing image', { type: 'error' });
          });
      }}
    >
      Publish Image
    </Button>
  );
}

function ActionButtons({ image, showAddToSource = false, setImage, publishedIdsObj = {} }: ActionButtonsTypes) {
  const {
    authFetch,
    session: { IMAGE_DOMAINS, property },
  } = useConfig();

  const publishedIds = Object.keys(publishedIdsObj);
  const IMAGE_DOMAIN = IMAGE_DOMAINS?.default;

  if (!checkIfPublished(image, property)) {
    return (
      image.access_mode !== 'local-upload' && (
        <Group gap="md" direction="row">
          <CopyMetadataButton
            image={image}
            publishedIdsObj={{ unpublished: { id: 'unpublished', label: 'unpublished', url: '' } }}
          />
          {setImage && <PublishButton image={image} setImage={setImage} authFetch={authFetch} property={property} />}
        </Group>
      )
    );
  }

  return (
    <Group gap="md" direction="row">
      <CopyMetadataButton image={image} publishedIdsObj={publishedIdsObj} />
      <VariantButton
        data-testid="copy-img-id"
        ButtonComponentEl={CopyToClipboard}
        tertiary
        label={`${Object.keys(publishedIdsObj).length === 1 ? Object.keys(publishedIdsObj)[0] : 'Image ID'}`}
        icon={IconCopy}
        items={generateItems({
          items: publishedIdsObj,
          iconTemplate: () => IconCopy,
          otherButtonPropTemplate: ({ id }) => {
            return {
              text: `${id}`,
            };
          },
          childrenTemplate: variantChildButtons,
        })}
      />

      <VariantButton
        data-testid="copy-img-url-btn"
        ButtonComponentEl={CopyToClipboard}
        tertiary
        label="Image URL"
        icon={IconCopy}
        items={generateItems({
          items: publishedIdsObj,
          iconTemplate: () => IconCopy,
          otherButtonPropTemplate: ({ id }) => {
            return {
              text: `${IMAGE_DOMAIN}${id}`,
              componentEl: 'a',
              target: '_blank',
              rel: 'noopener noreferrer',
              href: `${IMAGE_DOMAIN}${id}`,
            };
          },
          childrenTemplate: variantChildButtons,
        })}
      />

      {showAddToSource && publishedIds.length && <AddToSourceButton image={image as ImageType} />}
    </Group>
  );
}

export default ActionButtons;
