import { PropertyType } from '../../types';

class HTTPError extends Error {
  status: number;
  constructor(code: number, message?: string, extras?: any) {
    super(message);
    this.status = code;
  }
}

type AuthFetchType = {resource: string, options?: RequestInit, property: PropertyType, accessToken:string}

export const authFetch = ( { resource, options, property, accessToken } : AuthFetchType) => {
  return new Promise((resolve, reject) => {
    if (!property || accessToken === '') {
      let errorMessage = '';
      if (!property && accessToken === '') errorMessage = 'Property and access token are required';
      if (!property) errorMessage = 'Property is required';
      if (accessToken === '') errorMessage = 'Access token is required';
      reject(new HTTPError(400, errorMessage));
    }

    const headers = { ...(options?.headers || {}), ...{ 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` } };
    const updatedOptions = { ...options, ...{ headers } };
    const url = resource.replace(':property', property);

    return fetch(url, updatedOptions)  
      .then((response) => {
        if (response.status !== 200) {
          console.error('API Response ERROR: ', response.status, url, updatedOptions, response);
          reject(new HTTPError(response.status, `Error getting data`));
        }
        return response.json();
      })
      .then((json) => {
        resolve(json) 
      })
      .catch((error) => {
        console.error('Unknown API ERROR: ', url, updatedOptions, error);
        reject(new HTTPError(500, `Unknown error occurred`));
      });
  })
}