import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Group,
  Grid,
  Input,
  IconCaretLeft,
  Textarea,
  Select,
  Typography,
} from '@screentone/core';
import { Link } from 'react-router-dom';
import { useConfig } from '../../../hooks/useConfig';
import { helpers } from '../../../utils';

import type { ImageType, PropertyType } from '../../../types';

function DeveloperDocs() {
  const properties = helpers.getAvailablePropertiesLabels();
  const searchParams = new URLSearchParams(location.search);

  const { authFetch } = useConfig();
  const [config, setConfig] = useState({});

  const [property, setProperty] = useState((searchParams.get('property') || 'wsj') as PropertyType);
  const [id, setId] = useState(searchParams.get('id') as PropertyType);
  const [image, setImage] = useState<ImageType>();

  const [showLogo, setShowLogo] = useState(false);
  const [showText, setShowText] = useState(true);
  const [showUnderlay, setShowUnderlay] = useState(false);
  const [textOpacity, setTextOpacity] = useState(100);
  const [lineSpacing, setLineSpacing] = useState(120);
  const [text, setText] = useState(`EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY
        EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY        
EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY
        EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY
EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY
        EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY        
EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY
        EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY
EXAMPLE - INTERNAL USE ONLY        EXAMPLE - INTERNAL USE ONLY`);

  const [imageURL, setImageUrl] =
    useState<{
      backgroundUrl: string;
      url: string;
    }>();

  const formSubmit = () => {
    authFetch(`/api/${property}/${id}`)
      .then((img) => {
        setImage(img);
      })
      .catch((error) => {
        return { data: null, error };
      });
    authFetch(`/api/config/${property}`)
      .then((img) => {
        setConfig(img);
      })
      .catch((error) => {
        return { data: null, error };
      });
  };

  useEffect(() => {
    if (image?.tags?.includes(id)) {
      const IMAGE_DOMAIN = config.IMAGE_DOMAINS?.default;
      const host = config.IMAGE_DOMAINS?.hash;

      const cldItem = helpers.getCldTransformationImage({
        image,
        width: 1280,
        height: 640,
        host,
        publishedId: id,
      });

      const logo = showLogo
        ? `l_image:upload:logos:${property}/c_scale,co_rgb:fff,e_colorize:100,fl_relative,w_0.08/o_80/fl_layer_apply,g_north_west,x_50,y_20/`
        : '';
      const backgroundUrl = `${IMAGE_DOMAIN}/image/upload/${cldItem?.transformation}/e_colorize:30,co_rgb:fff/${cldItem.public_id}`;
      // e_gradient_fade,y_-0.5,b_black/
      // const underlay = 'e_gradient_fade,y_-0.5,b_black/';

      const font = property === 'wsj' ? 'georgia' : 'roboto';
      const fontSize = property === 'wsj' ? 60 : 80;
      const lineSpace = lineSpacing ? `_line_spacing_${lineSpacing}` : '';
      const underlay = showUnderlay ? 'e_gradient_fade,y_-0.5,b_black/' : '';
      const textTransformation = `l_text:${font}_${fontSize}_bold${lineSpace}:${encodeURIComponent(text).replace(
        '%2C',
        '%252C',
      )}},a_-45,co_rgb:fff,o_50,fl_no_overflow/`;
      // const textTransformation = `${underlay}c_fit,l_text:${font}_${fontSize}_bold${lineSpace}_alignment_right:${encodeURIComponent(
      //   text
      // ).replace('%2C', '%252C')},g_south_east,y_60,x_80,w_1100,h_500,co_rgb:fff,o_${textOpacity}/`;
      const url = `${IMAGE_DOMAIN}image/upload/${cldItem?.transformation.replace('f_auto,q_auto:eco', '')}${logo}${
        showText ? textTransformation : ''
      }f_auto,q_auto:eco/${cldItem.public_id}`;
      setImageUrl({
        backgroundUrl,
        url,
      });
    }
  }, [id, property, image, showLogo, showText, text, textOpacity, showUnderlay, lineSpacing]);

  const backStyles = {
    zIndex: 'var(--st-zindex-mid)',
    borderBottom: 'var(--st-border-width) solid var(--st-border-color)',
    backgroundColor: 'var(--st-page-bg)',
    padding: 'var(--st-spacer-md) 0',
    marginBottom: 'var(--st-spacer-lg)',
  };

  return (
    <>
      {id && property && (
        <div style={backStyles}>
          <Group align="space-between">
            <Button icon={IconCaretLeft} tertiary componentEl={Link} to={`/${property}/image/${id}`}>
              Image Details
            </Button>
          </Group>
        </div>
      )}
      <>
        <Grid>
          <Grid.Row>
            <Grid.Col xs={12}>
              <Typography variant="header1">Watermarking / Overlays</Typography>
              <Group valign="end" margin={{ bottom: 'md' }}>
                <FormLabel label="Property" labelPosition="top">
                  <Select
                    name="name"
                    value={property}
                    onChange={(event: any) => {
                      setProperty(event.target.value as PropertyType);
                    }}
                    readonly
                  >
                    {Object.keys(properties).map((e) => (
                      <option key={e} value={e}>
                        {properties[e as PropertyType]}
                      </option>
                    ))}
                  </Select>
                </FormLabel>
                <FormLabel label="id" labelPosition="top">
                  <Input readonly value={id} onChange={(event: any) => setId(event.target.value)} />
                </FormLabel>
                <Button primary onClick={() => formSubmit()}>
                  Submit
                </Button>
              </Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} sm={12} md={8} lg={6}>
              <Typography variant="header2">Social X Generator</Typography>
              {imageURL && (
                <Box>
                  <Box.Title>Social X Image</Box.Title>
                  <Box.Content>
                    <div
                      style={{
                        height: '268px',
                        background: 'red',
                        backgroundImage: `url(${imageURL.backgroundUrl})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        aspectRatio: '2',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        style={{
                          maxWidth: '512px',
                          height: '268px',
                          backgroundImage: `url(${imageURL.url})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          borderRadius: '16px',
                          margin: 'auto',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '12px',
                            left: '12px',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            color: 'white',
                            fontSize: '13px',
                            lineHeight: '16px',
                            padding: '4px',
                            borderRadius: '4px',
                          }}
                        >
                          {property}.com
                        </div>
                      </div>
                    </div>
                  </Box.Content>
                </Box>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={4} lg={6}>
              <Box>
                <Box.Title>Text</Box.Title>
                <Box.Content>
                  <FormLabel label="Show Logo" labelPosition="right" fullWidth>
                    <Checkbox checked={showLogo} onChange={() => setShowLogo(!showLogo)} />
                  </FormLabel>

                  <FormLabel label="Overlay Text" labelPosition="right" fullWidth>
                    <Checkbox checked={showText} onChange={() => setShowText(!showText)} />
                  </FormLabel>
                  {showText && (
                    <>
                      <FormLabel label="Text" labelPosition="top" fullWidth>
                        <Textarea value={text} onChange={(event: any) => setText(event.target.value)} />
                      </FormLabel>

                      <FormLabel label="Dark underlay" labelPosition="right" fullWidth>
                        <Checkbox checked={showUnderlay} onChange={() => setShowUnderlay(!showUnderlay)} />
                      </FormLabel>

                      <FormLabel label="lineSpacing" labelPosition="top" fullWidth>
                        <input
                          type="range"
                          min="0"
                          max="200"
                          value={lineSpacing}
                          onChange={(event: any) => {
                            setLineSpacing(event.target.value);
                          }}
                        />
                      </FormLabel>

                      <FormLabel label="Text Opacity" labelPosition="top" fullWidth>
                        <input
                          type="range"
                          min="0"
                          max="100"
                          step="10"
                          value={textOpacity}
                          onChange={(event: any) => {
                            setTextOpacity(event.target.value);
                          }}
                        />
                      </FormLabel>
                    </>
                  )}
                </Box.Content>
              </Box>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </>
    </>
  );
}

export default DeveloperDocs;
