import React from 'react';
import { Helmet as Head } from 'react-helmet';
import UploadLayout from '../../../../layouts/Upload';

// /[property]/upload
const UploadPage = () => {
  return (
    <>
      <Head>
        <title>Images - Upload</title>
      </Head>
      <UploadLayout />
    </>
  );
};

export default UploadPage;
