import Calendar, { calendarHooks, format } from '@screentone/addon-calendar';
import { Input, FormLabel, Select, Switch, Group, Textarea, Button, FormHelperText } from '@screentone/core';
import React from 'react';

import { constants, helpers } from '../../utils';
import type { EditImageMeta, DisableEditField, GraphicType } from '../../types';
import { requiredFieldsForPublisher, slugNormalize } from '../../utils/helpers';

import { useConfig } from '../../hooks/useConfig';

import styles from './EditMetadataForm.module.css';
import UseUploadType from '../../hooks/useUpload/types';
import AltTextTooltip from './AltTextTooltip.component';

interface EditImageMetaProps extends EditImageMeta {
  /** a flag that indicates whether or not one time use is selected  */
  disableOneTimeUse: boolean;
  /** a flag that indicates whether or not resize only is selected  */
  disableResize: boolean;
  /** a flag that indicates whether or the image is uncredited */
  uncredited?: boolean;
  /** a state setting function that updates the state of the image's metadata */
  onChange(payload: Partial<EditImageMeta>): void;
  /** set metadata to images */
  disableRequiredFields?: boolean;
  disableEditField?: DisableEditField;
  /** error status of calendar field  */
  calendarError: string;
  /** function to set error status of calendar field  */
  setCalendarError(error: string): void;
  /** a flag that indicates whether or not the image is dynamic */
  isDynamic?: boolean;
  validation?: Partial<UseUploadType.Validation.ValidFormFieldsType>;
}

const EditImageMetaForm = ({
  disableEditField = {},
  slug,
  caption,
  contact = '',
  credit,
  datePhotographed,
  headline,
  graphicType,
  oneTimeUse,
  disableOneTimeUse,
  altText,
  resizeOnly,
  disableResize,
  uncredited,
  background,
  onChange,
  disableRequiredFields = false,
  calendarError,
  setCalendarError,
  isDynamic = false,
  validation,
}: EditImageMetaProps) => {
  const renderNode = calendarHooks.usePortal();
  const {
    session: { property, SEARCH },
  } = useConfig();
  const publisherFields = requiredFieldsForPublisher(property, SEARCH?.OPTIONS?.FORM_VALIDATION);
  const requiredFields = requiredFieldsForPublisher(property, SEARCH?.OPTIONS?.FORM_VALIDATION, disableRequiredFields);

  // const { open, setOpen, componentRef } = useDropdownState();
  const getOneTimeUse = (oneTimeUseParam: boolean | undefined) => {
    const cleanCaption = caption?.replace('*** ONE-TIME USE ***', '').trim();

    return oneTimeUseParam ? cleanCaption : `*** ONE-TIME USE *** ${cleanCaption || ''}`.trim();
  };

  const getUncredited = (uncreditedParam: boolean) => {
    return uncreditedParam ? 'Uncredited' : '';
  };

  const { contact: disableContact = false, slug: disableSlug = false } = disableEditField;

  return (
    <>
      {!disableSlug && (
        <FormLabel fullWidth label="Image set label / Slug" required={requiredFields.slug}>
          <Input
            data-testid="slug-input"
            error={requiredFields.slug ? !slug?.length : false}
            onBlur={({ target }: { target: HTMLInputElement }) => onChange({ slug: slugNormalize(target.value) })}
            onChange={({ target }: { target: HTMLInputElement }) => onChange({ slug: slugNormalize(target.value) })}
            placeholder={requiredFields.slug ? 'Slug Required' : 'Slug'}
            value={slug}
          />
        </FormLabel>
      )}
      <FormLabel fullWidth label="Headline" required={requiredFields.headline}>
        <Input
          data-testid="edit-metadata-headline"
          onChange={({ target }: { target: HTMLInputElement }) => onChange({ headline: target.value.trimStart() })}
          onBlur={({ target }: { target: HTMLInputElement }) => {
            onChange({ headline: target.value.trimEnd() });
          }}
          placeholder={requiredFields.headline ? 'Headline Required' : 'Headline'}
          value={headline}
          error={requiredFields.headline ? !headline.length : false}
        />
      </FormLabel>
      <FormLabel fullWidth label="Caption" required={requiredFields.caption}>
        <Textarea
          data-testid="edit-metadata-caption"
          onChange={({ target }: { target: HTMLInputElement }) => onChange({ caption: target.value.trimStart() })}
          onBlur={({ target }: { target: HTMLInputElement }) => {
            onChange({ caption: target.value.trimEnd() });
          }}
          value={caption}
          placeholder={requiredFields.caption ? 'Caption Required' : 'Caption'}
          error={requiredFields.caption ? !caption.length : !!validation?.caption}
        />
        {validation?.caption && <FormHelperText error>{validation?.caption}</FormHelperText>}
      </FormLabel>
      <FormLabel
        fullWidth
        label={
          <Group align="space-between">
            <FormLabel label="Credit" required={requiredFields.credit} />
            {publisherFields.credit && (
              <Button
                data-testid="edit-metadata-uncredit"
                tertiary
                active={uncredited}
                onClick={() => onChange({ uncredited: !uncredited, credit: getUncredited(!uncredited) })}
                size="sm"
              >
                Uncredited
              </Button>
            )}
          </Group>
        }
      >
        <Input
          data-testid="edit-metadata-credit"
          onChange={({ target }: { target: HTMLInputElement }) => onChange({ credit: target.value.trimStart() })}
          onBlur={({ target }: { target: HTMLInputElement }) => {
            onChange({ credit: target.value.trimEnd() });
          }}
          placeholder="Credit"
          value={credit}
          disabled={uncredited}
          error={requiredFields.credit ? !credit.length : false}
        />
      </FormLabel>
      {!disableContact && (
        <FormLabel fullWidth label="Contact" required={requiredFields.contact}>
          <Input
            data-testid="edit-metadata-contact"
            onChange={({ target }: { target: HTMLInputElement }) => onChange({ contact: target.value.trimStart() })}
            onBlur={({ target }: { target: HTMLInputElement }) => {
              onChange({ contact: target.value.trimEnd() });
            }}
            placeholder="Contact"
            value={contact}
            error={requiredFields.contact ? !contact.length : false}
          />
        </FormLabel>
      )}
      <FormLabel
        data-testid="edit-metadata-date"
        fullWidth
        label="Date Photographed"
        required={requiredFields.datePhotographed}
      >
        <Calendar
          align="right"
          calendarProps={{ className: styles.calendar }}
          maxDate={new Date()}
          onSelect={(date: Date) => {
            // don't use the hook for managing state - multiple calendars rendered at same time effect same state
            onChange({
              datePhotographed: date ? format(date, constants.DATE_FORMATS.CLOUDINARY) : '',
            });

            setCalendarError('');
            if (date) {
              if (helpers.parseDate(format(date, constants.DATE_FORMATS.CLOUDINARY)) > helpers.getToday()) {
                setCalendarError('Date cannot be bigger than today');
              }
            }
          }}
          error={calendarError || (requiredFields.datePhotographed && !datePhotographed) || false}
          placeholder={`${constants.DATE_FORMATS.UPLOADS.EDIT_METADATA_FORM.toLowerCase()}`}
          renderNode={renderNode}
          selectedDate={helpers.parseDate(datePhotographed)}
          required={requiredFields.datePhotographed}
        />
      </FormLabel>
      <FormLabel fullWidth label="Graphic Type">
        <Select
          data-testid="edit-metadata-graphic"
          name="Type"
          onChange={({ target }: { target: HTMLInputElement }) =>
            onChange({ graphicType: target.value as GraphicType })
          }
          value={graphicType}
        >
          {constants.GRAPHIC_TYPES.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </FormLabel>
      <FormLabel fullWidth label={<AltTextTooltip />} required={requiredFields.altText}>
        <Textarea
          data-testid="altText-input"
          onChange={({ target }: { target: HTMLInputElement }) => onChange({ altText: target.value.trimStart() })}
          onBlur={({ target }: { target: HTMLInputElement }) => {
            onChange({ altText: target.value.trimEnd() });
          }}
          error={requiredFields.altText ? !altText?.length : false}
          placeholder="Alt Text - a description of the image for SEO & accessibility"
          value={altText}
        />
      </FormLabel>
      <Group direction="column" gap="xs">
        <FormLabel label="One-Time Use" labelPosition="right">
          <Switch
            data-testid="edit-metadata-onetime"
            checked={oneTimeUse}
            onChange={({ target }: { target: HTMLInputElement }) =>
              onChange({ oneTimeUse: target.checked, caption: getOneTimeUse(oneTimeUse) })
            }
            disabled={disableOneTimeUse}
          />
        </FormLabel>
        {!isDynamic && (
          <FormLabel label="Resize Only" labelPosition="right">
            <Switch
              data-testid="edit-metadata-resizeonly"
              checked={resizeOnly}
              onChange={({ target }: { target: HTMLInputElement }) => onChange({ resizeOnly: target.checked })}
              disabled={disableResize}
            />
          </FormLabel>
        )}
      </Group>
    </>
  );
};

export default EditImageMetaForm;
