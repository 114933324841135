import React from 'react';
import { createContext, useContext, useReducer, useState } from 'react';
import { cloneDeep } from 'lodash';

import { ImageType } from '../types/image';

export type Action =
  | { type: typeof actions.ADD_TO_STORY }
  | {
      type: typeof actions.ADD_IMAGE;
      payload: { image: ImageType | null; images?: ImageType[] | null; e?: any; id?: string };
    }
  | { type: typeof actions.REMOVE_IMAGE; payload: ImageType }
  | { type: typeof actions.REMOVE_IMAGE_ALL }
  | { type: typeof actions.UPDATE_IMAGE; payload: { image: ImageType } }
  | {
      type: typeof actions.ADD_TO_SELECTED_FOR_DRAG;
      payload: c;
    }
  | { type: typeof actions.CLEAR_SELECTED_FOR_DRAG }
  | { type: typeof actions.ADD_PUBLISHEDIDS_OBJ; payload: any }
  | { type: typeof actions.REMOVE_PUBLISHEDIDS_OBJ; payload: ImageType };
export type Dispatch = (action: Action) => void;
export type State = {
  images: ImageType[];
  selectedForDrag: ImageType[];
  lastSelectedDragIndex: number;
  publishedIdsObj: any;
};

export const actions = Object.freeze({
  ADD_TO_STORY: 'addToStory',
  ADD_IMAGE: 'addImage',
  REMOVE_IMAGE: 'removeImage',
  REMOVE_IMAGE_ALL: 'removeImageAll',
  ADD_TO_SELECTED_FOR_DRAG: 'addToSelectedForDrag',
  CLEAR_SELECTED_FOR_DRAG: 'clearSelectedForDrag',
  UPDATE_IMAGE: 'updateImage',
  ADD_PUBLISHEDIDS_OBJ: 'addPublisedIdsObj',
  REMOVE_PUBLISHEDIDS_OBJ: 'removePublisedIdsObj',
});

export type ActionsType = {
  ADD_TO_STORY: 'addToStory';
  ADD_IMAGE: 'addImage';
  REMOVE_IMAGE: 'removeImage';
  REMOVE_IMAGE_ALL: 'removeImageAll';
  ADD_TO_SELECTED_FOR_DRAG: 'addToSelectedForDrag';
  CLEAR_SELECTED_FOR_DRAG: 'clearSelectedForDrag';
};
type LightboxContextContextType =
  | {
      actions: typeof actions;
      dispatch: Dispatch;
      // effects: { addToStory(): void };
      state: State;
    }
  | undefined;

const LightboxContext = createContext<LightboxContextContextType>(undefined);

/** Arguments for UploadProvider */
type LightboxProviderArguments = {
  defaultImages?: ImageType[];
  children?: React.JSX.Element;
};

export const defaultLightboxState: State = {
  images: [],
  selectedForDrag: [],
  lastSelectedDragIndex: -1,
  publishedIdsObj: {},
};

const lightboxReducer = (state: State, action: Action): State => {
  switch (action.type) {
    /** remove an image */
    case actions.REMOVE_IMAGE: {
      return {
        ...state,
        images: state?.images?.filter((img) => img.asset_id !== action.payload.asset_id),
        selectedForDrag: [],
        lastSelectedDragIndex: -1,
      };
    }

    /** remove all images */
    case actions.REMOVE_IMAGE_ALL: {
      return {
        ...state,
        images: [],
        selectedForDrag: [],
        lastSelectedDragIndex: -1,
      };
    }

    /** add an image */
    case actions.ADD_IMAGE: {
      const { e, image, images } = action.payload;

      const newState = [...state.selectedForDrag];

      // the shift key was clicked with the "+" button
      // this means we should find the images in between state.lastSelectedDragIndex and `action.payload.image`
      if (e?.shiftKey && images) {
        // only dragging a single image, just add that to selected for drag
        const imageIndex = images.findIndex((img) => img.asset_id === action.payload.image?.asset_id);
        const selectedRange =
          imageIndex > state.lastSelectedDragIndex
            ? images.slice(state.lastSelectedDragIndex + 1, imageIndex + 1)
            : images.slice(imageIndex, state.lastSelectedDragIndex);

        newState.push(...selectedRange);
      }

      newState.push(...state.selectedForDrag);

      if (image && !newState.some((img) => img.asset_id === image.asset_id)) newState.push(image);

      return {
        ...state,
        images: Array.from(new Set([...state.images, ...newState])),
        selectedForDrag: [],
        lastSelectedDragIndex: -1,
      };
    }

    /** add to the list of images that are currently ready for dragging */
    case actions.ADD_TO_SELECTED_FOR_DRAG:
      return {
        ...state,
        selectedForDrag: action.payload.selectedForDrag,
        lastSelectedDragIndex: action.payload.lastSelectedDragIndex,
      };

    /** clear the list of images that are currently ready for dragging */
    case actions.CLEAR_SELECTED_FOR_DRAG:
      return {
        ...state,
        selectedForDrag: [],
        lastSelectedDragIndex: -1,
      };

    /** updates an image with updated data, used for add to story publisher */
    case actions.UPDATE_IMAGE: {
      const { image } = action.payload;

      const updatedImages = cloneDeep(state.images);
      const imageIndex = state.images.findIndex((lightboxImage) => lightboxImage?.asset_id === image?.asset_id);

      updatedImages[imageIndex] = image;

      return {
        ...state,
        images: [...updatedImages],
      };
    }

    case actions.ADD_PUBLISHEDIDS_OBJ: {
      const { image, id, publishedIdsObj } = action.payload;
      publishedIdsObj;
      return {
        ...state,
        publishedIdsObj: { ...state.publishedIdsObj, [image.asset_id]: publishedIdsObj[id] },
      };
    }

    case actions.REMOVE_PUBLISHEDIDS_OBJ: {
      const filtered = Object.keys(state?.publishedIdsObj)
        .filter((key) => key !== action.payload.asset_id)
        .reduce((obj: any, key) => {
          obj[key] = state?.publishedIdsObj[key];
          return obj;
        }, {});

      return {
        ...state,
        publishedIdsObj: filtered,
      };
    }

    /** handle undefined actions */
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

/** Provider for context */
export const LightboxProvider = ({ defaultImages, children }: LightboxProviderArguments) => {
  const [state, dispatch] = useReducer(
    lightboxReducer,
    defaultImages ? { images: defaultImages, selectedForDrag: [], lastSelectedDragIndex: -1 } : defaultLightboxState,
  );

  return <LightboxContext.Provider value={{ actions, dispatch, state }}>{children}</LightboxContext.Provider>;
};

/**
 * Hook for getting and setting information about images in the uploader
 * For more information about the pattern used, see https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const useLightbox = () => {
  const context = useContext(LightboxContext);

  if (context === undefined) {
    throw new Error('useLightbox must be used within a lightboxProvider');
  }

  return context;
};

export default useLightbox;
