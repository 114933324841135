import React, { LegacyRef, useEffect, useRef } from 'react';
import { Alert, Button, Group, IconError, Typography, IconUpload } from '@screentone/core';
import uniq from 'lodash/uniq';

import AddToSourceButton from '../../Buttons/AddToSource';
import ImageCard from '../../ImageCard';

import useAssetManager from '../../../hooks/useAssetManager';
import useUpload from '../../../hooks/useUpload';
import { useConfig } from '../../../hooks/useConfig';
import { localStorageHelper } from '../../../utils/helpers';

import { iframe } from '../../../utils';

import type { StatusType, ImageType, UploadImageFormats } from '../../../types';

import styles from './UploadStaging.module.css';

const UploadStaging = () => {
  const {
    session: { property },
  } = useConfig();
  const { effects, state, dispatch, actions, type } = useUpload();
  const { getLastUploadedImages, getImage } = useAssetManager();

  const latestUploads = getLastUploadedImages();
  const images = latestUploads.map((image) => getImage(image));

  const numberOfUploads = state.files.accepted.length;
  const numberOfUploadsCompleted = state.upload.status.filter((status) => status === 'complete').length;

  const additionalIframeActions = (image: ImageType) => {
    return iframe.isFrame() && <AddToSourceButton image={image} />;
  };
  useEffect(() => {
    effects.upload(type);
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  // used to get the width of the page wrapper for the error alert container
  const pageWrapperRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);

  const imagesToRender = type === 'dynamic' ? [state.files.accepted[0]] : state.files.accepted;

  // unique errors
  const errors = uniq(state.upload.error).filter((error) => !!error) as string[];

  return (
    <div className={`st_pagewrapper ${styles.pageWrapper}`} ref={pageWrapperRef}>
      {errors && (
        <div className={styles.errorContainer} style={{ width: pageWrapperRef.current?.clientWidth || 'inherit' }}>
          {errors.map((error) => (
            <Alert key={error} icon={IconError} margin={{ bottom: 'sm' }} type="error">
              {error}
              <Alert.Action
                onClick={() => {
                  if (type === 'dynamic') {
                    effects.retryUploadsPromo();
                  } else {
                    effects.retryUploads();
                  }
                }}
              >
                Retry?
              </Alert.Action>
            </Alert>
          ))}
        </div>
      )}

      <Typography margin={{ bottom: 'none', top: 'lg' }} variant="header3">
        Upload
      </Typography>
      {numberOfUploads !== numberOfUploadsCompleted ? (
        <Typography margin={{ bottom: 'lg' }}>Please wait while we upload the images.</Typography>
      ) : (
        <Typography margin={{ bottom: 'lg' }}>All Images uploaded.</Typography>
      )}

      <Group align="center" margin={{ bottom: 'xl' }}>
        {!imagesToRender.length &&
          latestUploads.length > 0 &&
          images.map((image) => {
            return (
              <ImageCard
                key={image.secure_url}
                image={image}
                additionalActions={additionalIframeActions(image)}
                status={'complete'}
              />
            );
          })}
        {property &&
          imagesToRender.map((_, i) => {
            try {
              return (
                <ImageCard
                  key={imagesToRender[i].name + i}
                  image={
                    type === 'dynamic' && numberOfUploads === numberOfUploadsCompleted && state.upload.response[i]
                      ? state.upload.response[i]
                      : state.upload.response[i] || {
                          bytes: state.files.accepted[i].size,
                          access_mode: 'local-upload',
                          asset_id: `upload_${i}`,
                          isDynamic: type === 'dynamic',
                          format: imagesToRender[i].type
                            .replace('image/', '')
                            .replace('jpeg', 'jpg') as UploadImageFormats,
                          metadata: {
                            ...state.metadata[i],
                            date_photographed: state.metadata[i]?.datePhotographed,
                            graphic_type: state.metadata[i]?.graphicType,
                          },
                          secure_url: state.render[i].secure_url,
                          width: state.render[i].width,
                          height: state.render[i].height,
                          tags: [],
                          aspect_ratio: state.render[i].width / state.render[i].height,
                          context: {
                            transformation_type: state.metadata[i].resizeOnly ? 'resize' : null,
                          },
                        }
                  }
                  additionalActions={state.upload.response[i] && additionalIframeActions(state.upload.response[i])}
                  status={state.upload.status[i] as StatusType}
                />
              );
            } catch (e) {
              return null;
            }
          })}
        <Group fullWidth align="end">
          <Button
            type="button"
            icon={IconUpload}
            secondary
            margin={{ top: 'md', bottom: 'lg' }}
            onClick={() => {
              dispatch({ type: actions.ALL.RESET_UPLOAD });
              localStorageHelper.deleteItem(property);
            }}
            disabled={numberOfUploads !== numberOfUploadsCompleted}
          >
            Upload More Images
          </Button>
        </Group>
      </Group>
    </div>
  );
};

export default UploadStaging;
