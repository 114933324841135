import { useMemo } from 'react';
import { format } from '@screentone/addon-calendar';

import { constants } from '../../utils';

import type { SearchOptionsType } from '../../types';
import { useConfig } from '../useConfig';

// TODO: Update SearchOptionsType to remove the cropped prop
const initialOptions: SearchOptionsType = {
  query: '',
  user: null,
  date: {
    since: '',
    until: '',
    range: ''
  },
  oneTimeUse: false,
  statusFilter: null,
  advancedFilter: {},
  sourceFilter: [],
  graphicTypesFilter: {},
};

type TUseInitialSearchOptionsParamsHook = {
  initialOptions: Partial<SearchOptionsType>;
};

function useInitialSearchOptions({ filters = {} }: { filters: any }): TUseInitialSearchOptionsParamsHook {

  const {
    session: { SEARCH, property } = {},
  } = useConfig();

  const defaultBrandOptions: Partial<SearchOptionsType> = useMemo(() => {
    const { DATE_RANGE = '', ...defaults } = SEARCH?.DEFAULTS || {};

    const date = {
      since: '',
      until: '',
      range: ''
    };

    if (DATE_RANGE) {
      const { startDate, endDate } = filters[DATE_RANGE as string] || {};
      if (startDate) date.since = format(startDate, constants.DATE_FORMATS.CLOUDINARY);
      if (endDate) date.until = format(endDate, constants.DATE_FORMATS.CLOUDINARY);
      date.range = typeof DATE_RANGE === 'string' ? DATE_RANGE : '';
    }

    return {
      ...initialOptions,
      ...defaults,
      date,
    };
  }, [SEARCH?.DEFAULTS, property]);

  return { initialOptions: defaultBrandOptions };
}

export default useInitialSearchOptions;
