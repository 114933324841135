import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Input,
  Select,
  Group,
  FormLabel,
  Button,
  Box,
  Alert,
  Typography,
  IconError,
  IconCaretLeft,
} from '@screentone/core';

import { fnSelectRenderer, DEFAULT_PROPERTY_CONFIG as propertiesConfig } from '../../../utils/fnSelect';
import { formatBytes, getAvailablePropertiesLabels } from '../../../utils/helpers';

import type { PropertyType } from '../../../types';
import useConfig from '../../../hooks/useConfig';

const backStyles = {
  zIndex: 'var(--st-zindex-mid)',
  borderBottom: 'var(--st-border-width) solid var(--st-border-color)',
  backgroundColor: 'var(--st-page-bg)',
  padding: '0 var(--st-spacer-md) var(--st-spacer-md) var(--st-spacer-md)',
  marginBottom: 'var(--st-spacer-lg)',
};

function DeveloperDocs() {
  const properties = getAvailablePropertiesLabels();

  const searchParams = new URLSearchParams(location.search);
  const [property, setProperty] = useState((searchParams.get('property') || 'wsj') as PropertyType);
  const { authFetch } = useConfig();

  const resourceType = 'image';
  const [formData, setFormData] = useState({
    id: searchParams.get('id') || '',
    width: searchParams.get('width') || 480,
    height: searchParams.get('height') || 480,
    size: searchParams.get('size') || '',
    pixelratio: searchParams.get('dpr') || '',
    ext: '',
    named: '',
  });

  const getFormattedInputs = (d: any) => {
    const { id, ...rest } = d;
    const newInputs: any = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(rest || {})) {
      if (value && value !== '') {
        newInputs.push(`$${key}_!${value}!`);
      }
    }

    return newInputs;
  };

  const source = ['t_dj_default_renderer'];
  const [fnSelectResponse, setFnSelectResponse] = useState<any>({});
  const [imageHeaders, setImageHeaders] = useState<any>({});

  const [imageUrl, setImageUrl] = useState('');
  const [djUrl, setDjUrl] = useState('');
  const [transformationUrl, setTransformationUrl] = useState('');

  const getImageData = (src: any) => {
    const image = new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const size = { height: img.height, width: img.width };
        resolve(size);
      };
      img.onerror = resolve;
      img.src = src;
    });
    const header = fetch(src, { mode: 'cors' }).then((response) => {
      const contentLength = response.headers.get('content-length');
      const fileSize = formatBytes(Number(contentLength));
      const error = response.headers.get('x-cld-error');
      console.log('error: ', error);
      const errorSplit = error?.split('{');
      const errorMessages = errorSplit?.[0];
      console.log('errorMessages: ', errorMessages);

      const errorJson = new Function(`return ${error?.replace(errorMessages || '', '')}`)();
      console.log('errorJson: ', errorJson);

      const data = {
        error: errorMessages,
        errorJson: errorJson,
        contentType: response.headers.get('content-type'),
        contentLength,
        fileSize,
      };
      // console.log('data: ', data);
      return data;
    });
    return Promise.all([header, image])
      .then(([h, i]) => ({ ...h, ...(i as Object) }))
      .catch((error) => {
        console.error('getImageData error: ', error);
      });
  };

  const formChange = (field: string, value: string) => {
    setFormData({ ...formData, ...{ [field]: value } });
  };

  const formSubmit = async () => {
    const inputs = getFormattedInputs(formData);
    setFnSelectResponse({});
    setImageHeaders({});
    setImageUrl('');
    setDjUrl('');
    setTransformationUrl('');
    const config = await authFetch(`/api/config/${property}`)
      .then((config: any) => {
        return config;
      })
      .catch((error) => {
        return { data: null, error };
      });

    const cname = config.IMAGE_DOMAINS?.default;
    const md5 = config.IMAGE_DOMAINS?.hash;

    const transformations = `$host_!${md5}!,${[...inputs, ...source].join(',')}`;
    const { id, ext, named, ...queryPrams } = formData;

    const jsonUrl = `${cname}${resourceType}/list/${id}.json`;
    const qs = Object.entries(queryPrams || {})
      .map(([key, value]) => value && `${key === 'pixelratio' ? 'pixel_ratio' : key}=${value}`)
      .filter((x) => !!x)
      .join('&');
    const imageHeaderUrl = `${cname}${id}${named ? `/${named}` : ''}${ext ? `.${ext}` : ''}${qs ? `?${qs}` : ''}`;

    fetch(jsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText || 'Unknown Error');
        }
        return response.json();
      })
      .then((data) => {
        getImageData(imageHeaderUrl)
          .then((headers: any) => {
            setImageHeaders(headers);
          })
          .finally(() => {
            try {
              const getDocument = () => {
                return JSON.stringify([...data.resources]);
              };
              const getContext = () => {
                return {
                  resource: id,
                  transformation: `$host_!${md5}!,${inputs.join(',')}`,
                };
              };

              setImageUrl(`${cname}${resourceType}/list/${transformations}/${id}.json`);
              setDjUrl(imageHeaderUrl);

              const res = fnSelectRenderer(getDocument, getContext);
              const url = `${cname}image/upload/${res.transformation}/${res.public_id}`;
              setTransformationUrl(url);
              setFnSelectResponse(res);
            } catch (error) {
              console.error('error: ', error);
            }
          });
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  return (
    <>
      {formData.id.length > 6 && property && (
        <div style={backStyles}>
          <Group align="space-between">
            <Button icon={IconCaretLeft} tertiary componentEl={Link} to={`/${property}/image/${formData.id}`}>
              Image Details
            </Button>
          </Group>
        </div>
      )}
      <Typography variant="header1" margin={{ top: 'lg' }}>
        FN Selects Renderer Debugger
      </Typography>
      <Group valign="end" margin={{ bottom: 'md' }}>
        <FormLabel label="Property" labelPosition="top">
          <Select
            name="name"
            value={property}
            onChange={(event: any) => {
              setProperty(event.target.value as PropertyType);
            }}
          >
            {Object.keys(properties).map((e) => (
              <option key={e} value={e}>
                {properties[e as PropertyType]}
              </option>
            ))}
          </Select>
        </FormLabel>
        <FormLabel label="id" labelPosition="top">
          <Input value={formData.id} onChange={(event: any) => formChange('id', event.target.value)} />
        </FormLabel>
        <FormLabel label="named" labelPosition="top">
          <Select name="name" value={formData.named} onChange={(event: any) => formChange('named', event.target.value)}>
            <option value="">-- None --</option>
            <option value="social">social</option>
            <option value="preview">Preview</option>
            <option value="previewSquareThumb">previewSquareThumb</option>
            <option value="">----------</option>
            {Object.keys(propertiesConfig[property].SIZES).map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </FormLabel>

        <FormLabel label="ext" labelPosition="top">
          <Select name="name" value={formData.ext} onChange={(event: any) => formChange('ext', event.target.value)}>
            <option value="">Auto</option>
            <option value="jpg">jpg</option>
            <option value="png">png</option>
            <option value="gif">gif</option>
          </Select>
        </FormLabel>
        <FormLabel label="width" labelPosition="top" style={{ width: '70px' }}>
          <Input value={formData.width} onChange={(event: any) => formChange('width', event.target.value)} />
        </FormLabel>
        <FormLabel label="height" labelPosition="top" style={{ width: '70px' }}>
          <Input value={formData.height} onChange={(event: any) => formChange('height', event.target.value)} />
        </FormLabel>
        <FormLabel label="size" labelPosition="top" style={{ width: '70px' }}>
          <Input value={formData.size} onChange={(event: any) => formChange('size', event.target.value)} />
        </FormLabel>
        <FormLabel label="pixelratio" labelPosition="top" style={{ width: '70px' }}>
          <Input value={formData.pixelratio} onChange={(event: any) => formChange('pixelratio', event.target.value)} />
        </FormLabel>
        <Button primary onClick={() => formSubmit()}>
          Submit
        </Button>
      </Group>

      {fnSelectResponse && (
        <Box>
          <Box.Title>Image Details</Box.Title>
          <Box.Content>
            {imageHeaders?.error && (
              <Alert type="error" icon={IconError} inline>
                {imageHeaders.error}
              </Alert>
            )}
            {imageHeaders?.errorJson && (
              <code>
                <pre>{JSON.stringify(imageHeaders.errorJson, null, 2)}</pre>
              </code>
            )}
            {imageHeaders?.contentType && (
              <p>
                <strong>Content Type:</strong> {imageHeaders.contentType}
              </p>
            )}
            {imageHeaders?.fileSize && (
              <p>
                <strong>File Size:</strong> {imageHeaders.fileSize} ({imageHeaders.contentLength})
              </p>
            )}
            {imageHeaders?.width && (
              <p>
                <strong>Width:</strong> {imageHeaders.width}px{' '}
                {formData.pixelratio && formData.pixelratio !== '1'
                  ? `(${imageHeaders.width / parseFloat(formData.pixelratio)}px)`
                  : ''}
              </p>
            )}
            {imageHeaders?.height && (
              <p>
                <strong>Height:</strong> {imageHeaders.height}px{' '}
                {formData.pixelratio && formData.pixelratio !== '1'
                  ? `(${imageHeaders.height / parseFloat(formData.pixelratio)}px)`
                  : ''}
              </p>
            )}
            {fnSelectResponse?.transformation && (
              <p>
                <strong>Transformation:</strong> {fnSelectResponse?.transformation}
              </p>
            )}
            {fnSelectResponse?.public_id && (
              <p>
                <strong>Public ID:</strong> {fnSelectResponse?.public_id}
              </p>
            )}
            {transformationUrl !== '' && (
              <p>
                <strong>CLD URL transformation:</strong>{' '}
                <a href={transformationUrl} target="_blank">
                  {transformationUrl}
                </a>
              </p>
            )}
            {/* eslint-disable-next-line @next/next/no-img-element */}
            {transformationUrl !== '' && <img src={transformationUrl} alt="CLD" style={{ maxWidth: '100%' }} />}
            {djUrl !== '' && (
              <p>
                <strong>DJ Brand URL:</strong>{' '}
                <a href={djUrl} target="_blank">
                  {djUrl}
                </a>
              </p>
            )}
            {/* eslint-disable-next-line @next/next/no-img-element */}
            {djUrl !== '' && <img src={djUrl} alt="CLD" style={{ maxWidth: '100%' }} />}
            {imageUrl !== '' && (
              <p>
                <strong>Internal CLD URL:</strong>{' '}
                <a href={imageUrl} target="_blank">
                  {imageUrl}
                </a>
              </p>
            )}
            {/* eslint-disable-next-line @next/next/no-img-element */}
            {imageUrl !== '' && <img src={imageUrl} alt="CLD" style={{ maxWidth: '100%' }} />}
          </Box.Content>
          {fnSelectResponse && (
            <Box.Content>
              <code>
                <pre>{JSON.stringify(fnSelectResponse, null, 2)}</pre>
              </code>
            </Box.Content>
          )}
        </Box>
      )}
    </>
  );
}

export default DeveloperDocs;
